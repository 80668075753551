import type { TravelFormProps } from '@/features/travel-form/travel-form';
import type { TravelFormV1Attributes } from '@/features/travel-form/travel-form-v1/travel-form-v1-type';

import { HtmlTextWrapper, ResponsiveImage } from '@/core/features';
import TravelForm from '@/features/travel-form/travel-form';

import styles from './travel-form-v1.module.scss';

type TravelFormV1Props = {
    banner: TravelFormV1Attributes['banner'];
} & TravelFormProps;

export default function TravelFormV1(props: TravelFormV1Props) {
    const { banner } = props;

    if (!banner?.title && banner?.htmlTitle) {
        return (
            <div className={`full-width ${styles.bannerWithoutImageWrapper}`}>
                <h1 className={`flex-start-vertical ${styles.textWrapper}`}>
                    <HtmlTextWrapper
                        className={'font-size-24'}
                        htmlText={banner.htmlTitle}
                    />
                </h1>
                {banner.subtitle && <span className={'font-size-18'}>{banner.subtitle}</span>}
                <TravelFormWrapper {...props} />
            </div>
        );
    }

    if (!banner || !banner.image) {
        return (
            <div className={'full-width content-box'}>
                <TravelFormWrapper {...props} />
            </div>
        );
    }

    return (
        <div className={'full-width'}>
            <div className={styles.bannerContentWrapper}>
                <div className={'absolute-top'}>
                    <ResponsiveImage
                        alt={banner.image.imageUrl}
                        fetchPriority={'high'}
                        height={280}
                        layoutWidth={100}
                        preload={true}
                        url={banner.image.imageUrl}
                    />
                    <div className={`absolute-full ${styles.bannerImageOverlay}`} />
                </div>
                <div className={styles.bannerContent}>
                    <div className={`flex-start-vertical ${styles.textWrapper}`}>
                        {banner.title && <h1 className={styles.title}>{banner.title}</h1>}
                        {banner.subtitle && <span className={styles.subtitle}>{banner.subtitle}</span>}
                    </div>
                    <TravelFormWrapper {...props} />
                </div>
            </div>
        </div>
    );
}

const TravelFormWrapper = ({
    activityRequestTarget,
    airportsRequestTarget,
    dateRequestTarget,
    destinationChangeRequestTarget,
    destinationRequestTarget,
    formConfiguration,
    onOverlayClose,
    onOverlayCtaClick,
    overlayType,
    travelForm,
}: TravelFormProps) => {
    return (
        <TravelForm
            activityRequestTarget={activityRequestTarget}
            airportsRequestTarget={airportsRequestTarget}
            dateRequestTarget={dateRequestTarget}
            destinationChangeRequestTarget={destinationChangeRequestTarget}
            destinationRequestTarget={destinationRequestTarget}
            formConfiguration={formConfiguration}
            noBackground={true}
            onOverlayClose={onOverlayClose}
            onOverlayCtaClick={onOverlayCtaClick}
            overlayType={overlayType}
            travelForm={travelForm}
        />
    );
};
