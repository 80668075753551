import type { OnClickEvent } from '@/core/types/react-overrides';
import type { TravelFormAirportResult } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import CheckBox from '@/core/components/check-box/check-box';
import KeyboardNavigationItemDesktop from '@/core/components/dropdown/keyboard-navigation-item-desktop';
import {
    useActiveKeyboardItemName,
    useActiveKeyboardNavigationContainerIdAtom,
} from '@/core/components/dropdown/use-keyboard-navigation-container-desktop';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './travel-form-airport-item-desktop.module.scss';

const bem = bemModule(styles);
export default function AirportItem({
    airport,
    hasCheckbox = true,
    isDefault,
    isSelected,
    onAirportClick,
}: {
    airport: TravelFormAirportResult;
    hasCheckbox?: boolean;
    isDefault: boolean;
    isSelected: boolean;
    onAirportClick: (airport: TravelFormAirportResult, event: OnClickEvent, enableNextInputOverlay?: boolean) => void;
}) {
    const activeKeyboardItemName = useActiveKeyboardItemName();
    const activeKeyboardNavigationContainerId = useActiveKeyboardNavigationContainerIdAtom();
    return (
        <KeyboardNavigationItemDesktop
            name={airport.name}
            onSelect={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => onAirportClick(airport, event, true)}
        >
            <div
                className={`flex-space-between-horizontal ${styles.airport}`}
                data-qa-id={'qa-travel-form-airport-desktop'}
                onClick={(event) => onAirportClick(airport, event)}
            >
                <div
                    className={bem(styles.airportWrapper, {
                        isDefault,
                        isHighlighted:
                            activeKeyboardItemName === airport.name &&
                            activeKeyboardNavigationContainerId === 'travel-form-airport-overlay-container-desktop',
                    })}
                >
                    <span className={styles.airportName}>
                        <span className={styles.airportNameName}>{airport.name}</span>
                        <span className={styles.airportNameCode}>{airport.code}</span>
                    </span>
                    {hasCheckbox && (
                        <span className={styles.airportCheckbox}>
                            <CheckBox
                                isSelected={isSelected}
                                onClick={(event) => onAirportClick(airport, event)}
                            />
                        </span>
                    )}
                    {!hasCheckbox && <p className={styles.allAirportsSelect}>wählen</p>}
                </div>
            </div>
        </KeyboardNavigationItemDesktop>
    );
}
