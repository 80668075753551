import type { DatePickerDateRange } from '@/core/features/date-picker/date-picker-types';
import type { TravelFormDataV1Attributes } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type {
    ApplyTravelFormFilter,
    TravelFormDesktopFormConfiguration,
} from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';

import React from 'react';

import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import {
    dateSelectHandler,
    dateToApiDate,
    getDatesBetween,
    getFormatedDateAsWeekdayDayMonth,
} from '@/core/features/date/date';
import TravelFormInputWrapperDesktop from '@/features/travel-form/travel-form-desktop/travel-form-input-desktop/travel-form-input-desktop';
import TravelFormDatePickerOverlayDesktop from '@/features/travel-form/travel-form-overlay/travel-form-date-picker-overlay/travel-form-date-picker-overlay-desktop/travel-form-date-picker-overlay-desktop';
import useDatePicker from '@/features/travel-form/travel-form-overlay/travel-form-date-picker-overlay/use-date-picker';
import { useIsTravelFormDataLoading } from '@/features/travel-form/travel-form-state';

type TravelFormDestinationOverlayContainerProps = {
    date: TravelFormDataV1Attributes['filters']['date'] | undefined;
    dateRequestTarget: string | undefined;
    formConfiguration: TravelFormDesktopFormConfiguration;
    hasError: boolean;
    inputName: TravelFormOverlayName;
    isInputDisabled: boolean;
    isLastOverlay?: boolean;
    isOverlayVisible: boolean;
    onInputClick: (inputName: TravelFormOverlayName) => void;
    onOverlayClose: () => void;
    onOverlayCtaClick: (config: ApplyTravelFormFilter) => void;
    onTravelFormCtaClick?: () => void;
    openNextInputOverlay?: () => void;
};

export default function TravelFormDatePickerOverlayContainerDesktop({
    date,
    dateRequestTarget,
    formConfiguration,
    hasError,
    inputName,
    isInputDisabled,
    isLastOverlay = false,
    isOverlayVisible,
    onInputClick,
    onOverlayClose,
    onOverlayCtaClick,
    onTravelFormCtaClick,
    openNextInputOverlay,
}: TravelFormDestinationOverlayContainerProps) {
    const {
        activeDateSelectionType,
        currentDateRange,
        disabledDaysMatcher,
        lastNavigatableMonth,
        setCurrentDateRange,
        setInitialDateSelection,
    } = useDatePicker(
        /** Setting 0 / null by default to satisfy typescript, since
         * we can't call useDatePicker hook conditionally. */
        date?.selectedDate ?? null,
        date?.daysToNextBookableDate ?? 0,
        date?.maxPossibleDayRange ?? 0,
        date?.numOfMonthFromNow ?? 0,
    );

    const inputConfiguration = formConfiguration.fields?.date;
    const inputLabel = date?.inputLabel ?? inputConfiguration?.inputLabel;
    const [wasActivelyUsed, setWasActivelyUsed] = React.useState<boolean>(false);
    const isTravelFormDataLoading = useIsTravelFormDataLoading();
    const isSpacePressed = React.useRef(false);

    //Work around for finding out if the datepicker date was selected by the keyboard or not
    const handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === ' ') {
            isSpacePressed.current = true;
        }
    };

    const clearSpacePress = () => {
        isSpacePressed.current = false;
    };

    React.useEffect(() => {
        if (isOverlayVisible) {
            window.addEventListener('keyup', handleKeyUp);
            window.addEventListener('keydown', clearSpacePress); // Reset on any key down
        }
        return () => {
            window.removeEventListener('keyup', handleKeyUp);
            window.removeEventListener('keydown', clearSpacePress);
        };
    }, [isOverlayVisible]);

    const onDateSelect = (dateRage: DatePickerDateRange | undefined) => {
        if (!date) {
            return;
        }
        setCurrentDateRange((previousDateRange) => {
            const nextDateRange = dateSelectHandler(dateRage, activeDateSelectionType, previousDateRange);

            if (!nextDateRange?.from || !nextDateRange?.to) {
                return nextDateRange;
            }

            setWasActivelyUsed(true);

            const isLessThenPrevDate =
                previousDateRange?.from && new Date(nextDateRange.from) < new Date(previousDateRange.from);

            const isMaxDayRangeExceeded =
                getDatesBetween(nextDateRange.from, nextDateRange.to).length > date.maxPossibleDayRange;

            if (isLessThenPrevDate || isMaxDayRangeExceeded) {
                return { from: nextDateRange.from, to: undefined };
            }

            onOverlayCtaClick({
                filterName: date.name,
                filterValue: {
                    end: dateToApiDate(nextDateRange.to),
                    start: dateToApiDate(nextDateRange.from),
                },
                requestTargetOverride: dateRequestTarget,
            });
            if (isSpacePressed.current) {
                isSpacePressed.current = false;
                openNextInputOverlay?.();
            }
            return nextDateRange;
        });
    };

    const handleOverlayClose = () => {
        if (!currentDateRange?.to) {
            setInitialDateSelection();
        }
        onOverlayClose();
    };

    const placeholderText = React.useMemo(() => {
        const placeholderText = date?.placeholderText ?? inputConfiguration?.placeholderText ?? 'beliebiger Zeitraum';

        const selectedPlaceholderTextFrom = currentDateRange?.from
            ? getFormatedDateAsWeekdayDayMonth(currentDateRange.from)
            : '';
        const selectedPlaceholderTextTo = currentDateRange?.to
            ? ` - ${getFormatedDateAsWeekdayDayMonth(currentDateRange?.to)}`
            : '';
        const selectedPlaceholderText = `${selectedPlaceholderTextFrom}${selectedPlaceholderTextTo}`;

        if ((isOverlayVisible || isTravelFormDataLoading) && selectedPlaceholderText.length) {
            return selectedPlaceholderText;
        }

        return placeholderText;
    }, [
        currentDateRange?.from,
        currentDateRange?.to,
        date?.placeholderText,
        inputConfiguration?.placeholderText,
        isOverlayVisible,
        isTravelFormDataLoading,
    ]);

    return (
        <KeyboardNavigationContainerDesktop
            autoFocus={true}
            className={'flex-column width-100 relative row-gap-1'}
            containerId={'travel-form-date-picker-overlay-container-desktop'}
            hasNoKeyboardNavigationItem={true}
            isActive={isOverlayVisible}
            onCtaClick={() => {
                onTravelFormCtaClick?.();
            }}
            onFocus={() => {
                if (!isOverlayVisible) {
                    onInputClick(inputName);
                }
            }}
        >
            <TravelFormInputWrapperDesktop
                ctaText={date?.ctaText ?? 'Reisezeitraum auswählen'}
                errorLabel={date?.error?.text}
                filterValue={
                    isTravelFormDataLoading ? placeholderText : wasActivelyUsed ? (date?.placeholderText ?? null) : null
                }
                hasError={hasError}
                hideCta
                inputSize={formConfiguration.inputSize}
                isInputDisabled={!isOverlayVisible || isInputDisabled}
                isLastOverlay={isLastOverlay}
                isOverlayVisible={isOverlayVisible}
                label={inputLabel ?? 'Reisezeitraum'}
                onClick={() => onInputClick(inputName)}
                onClose={handleOverlayClose}
                placeholder={placeholderText}
                qaId={'qa-travel-form-input-date-desktop'}
                type={'cta'}
            >
                <TravelFormDatePickerOverlayDesktop
                    disabledDays={disabledDaysMatcher}
                    fromDate={new Date()}
                    onDateSelect={onDateSelect}
                    selectedDate={currentDateRange}
                    toDate={lastNavigatableMonth}
                />
            </TravelFormInputWrapperDesktop>
        </KeyboardNavigationContainerDesktop>
    );
}
