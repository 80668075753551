import React from 'react';

import { MinusIcon } from '@/assets/icons/minus';
import { PlusIcon } from '@/assets/icons/plus';
import { decrement, increment } from '@/core/components/counter/counter-service';
import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import KeyboardNavigationItemDesktop from '@/core/components/dropdown/keyboard-navigation-item-desktop';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';

import styles from '../counter-with-label.module.scss';

const bem = bemModule(styles);

type CounterWithLabelProps = {
    count: number | string;
    disableIncrease?: boolean;
    exeedableText?: string;
    iconSize?: number;
    isLabelError?: boolean;
    isValueError?: boolean;
    keyboardNavigationContainerId: string;
    label: string;
    labelPosition?: 'space-between' | 'start';
    max?: number;
    min: number;
    onDecrease: (nextCount: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onIncrease: (nextCount: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onKeyboardActive?: () => void;
    steps?: number;
};

export default function CounterWithLabelDesktop({
    count,
    disableIncrease,
    exeedableText,
    iconSize = 30,
    isLabelError,
    isValueError,
    keyboardNavigationContainerId,
    label,
    labelPosition = 'start',
    max,
    min,
    onDecrease,
    onIncrease,
    onKeyboardActive,
    steps = 1,
}: CounterWithLabelProps) {
    const isMinCount = count === min;
    const handleDecrement = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (typeof count !== 'number') {
            return;
        }
        return decrement(event, count, steps, min, onDecrease);
    };

    const handleIncrement = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (typeof count !== 'number') {
            return;
        }
        return increment(event, count, steps, onIncrease, max);
    };

    const keyboardContainerStyle: React.CSSProperties = {
        borderRadius: '10px',
        outline: '10px solid #005ea8',
        outlineOffset: '4px',
        outlineWidth: '2px',
    };

    return (
        <div className={bem(styles.counterWrapper, { [labelPosition]: true })}>
            <span className={bem(styles.label, { isLabelError })}>{label}</span>
            <KeyboardNavigationContainerDesktop
                activeOnFocus={true}
                containerId={keyboardNavigationContainerId}
                containerStyleWhileActive={keyboardContainerStyle}
                hasCustomEvents={true}
                onFocus={onKeyboardActive}
            >
                <div className={`flex-space-between-horizontal ${styles.upDownWrapper}`}>
                    <button
                        className={`flex-center ${bem(styles.button, { isMinCount })}`}
                        data-qa-id={'qa-counter-with-label-minus-button'}
                        onClick={handleDecrement}
                        style={{ height: iconSize, width: iconSize }}
                        tabIndex={-1}
                    >
                        <KeyboardNavigationItemDesktop
                            customEventKey={'ArrowDown'}
                            name={'decrease'}
                            onSelect={handleDecrement}
                        />

                        <KeyboardNavigationItemDesktop
                            customEventKey={'ArrowLeft'}
                            name={'decrease'}
                            onSelect={handleDecrement}
                        />
                        <MinusIcon
                            color={isMinCount ? color('dustyGray') : color('white')}
                            height={iconSize}
                            width={iconSize}
                        />
                    </button>
                    <div className={bem(styles.count, { isValueError })}>
                        {exeedableText ? <span>{exeedableText}</span> : count}
                    </div>

                    <button
                        className={`flex-center ${bem(styles.button, { disableIncrease })}`}
                        data-qa-id={'qa-counter-with-label-plus-button'}
                        onClick={handleIncrement}
                        style={{ height: iconSize, width: iconSize }}
                        tabIndex={-1}
                    >
                        <KeyboardNavigationItemDesktop
                            customEventKey={'ArrowUp'}
                            name={'increase'}
                            onSelect={handleIncrement}
                        />
                        <KeyboardNavigationItemDesktop
                            customEventKey={'ArrowRight'}
                            name={'increase'}
                            onSelect={handleIncrement}
                        />
                        <PlusIcon
                            color={disableIncrease ? color('dustyGray') : color('white')}
                            height={iconSize}
                            width={iconSize}
                        />
                    </button>
                </div>
            </KeyboardNavigationContainerDesktop>
        </div>
    );
}
