import type { TravelFormDataV1Attributes } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { AllocationState } from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-types';

import React from 'react';

import CounterWithLabelDesktop from '@/core/components/counter/desktop/counter-with-label-desktop';
import { bemModule } from '@/core/utils/bem-classname';
import TravelFormAgeAllocationDesktop from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/age-allocation/age-allocation-desktop/travel-form-room-age-allocation-desktop';
import TravelFormRoomAllocationLayerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/room-allocation/room-allocation-desktop/travel-form-room-allocation-layer-desktop';
import TravelFormAdultRoomAllocationPreview from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/room-allocation/travel-form-room-allocation-preview';
import { TravelFormRoomAllocationShimmerBoxDesktop } from '@/features/travel-form/travel-form-overlay/travel-form-shimmer-boxes-desktop';

import styles from './travel-form-room-allocation-desktop.module.scss';

const bem = bemModule(styles);

type TravelFormRoomAllocationProps = {
    addAdult: (roomIndex?: number) => void;
    addChild: (roomIndex?: number) => void;
    addRoom: () => void;
    allocateAge: (childIndex: number, value: number) => void;
    allocation: AllocationState;
    isCustomAllocationLayerOpen: boolean;
    isLoading: boolean;
    isValidAllocation: boolean;
    removeAdult: (roomIndex?: number) => void;
    removeChild: (roomIndex?: number) => void;
    removeRoom: (roomIndex?: number) => void;
    roomAllocation: TravelFormDataV1Attributes['filters']['roomAllocation'];
    setCustomAllocationLayerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function TravelFormRoomAllocationDesktop({
    addAdult,
    addChild,
    addRoom,
    allocateAge,
    allocation,
    isCustomAllocationLayerOpen,
    isLoading,
    isValidAllocation,
    removeAdult,
    removeChild,
    removeRoom,
    roomAllocation,
    setCustomAllocationLayerOpen,
}: TravelFormRoomAllocationProps) {
    if (!roomAllocation || isLoading) {
        return <TravelFormRoomAllocationShimmerBoxDesktop />;
    }

    return (
        <div data-qa-id={'qa-travel-form-desktop-allocation-overlay'}>
            <div className={styles.roomAllocationOverlayContent}>
                {!isCustomAllocationLayerOpen && (
                    <div
                        className={bem(styles.roomAllocationOverlayBody, {
                            isExtended: allocation.childrenCount > 0 || allocation.roomsCount > 1,
                        })}
                    >
                        <>
                            <CounterWithLabelDesktop
                                count={allocation.adultsCount}
                                disableIncrease={
                                    allocation.adultsCount === roomAllocation.configuration.limits.adults.max ||
                                    allocation.adultsCount >=
                                        roomAllocation.configuration.limits.maxAdultsPerRoom * allocation.roomsCount + 1
                                }
                                iconSize={38}
                                isLabelError={
                                    allocation.errors.exceedsMaxAdultsPerRoom.isError ||
                                    allocation.errors.minAdultsPerRoom.isError
                                }
                                isValueError={
                                    allocation.errors.exceedsMaxAdultsPerRoom.isError ||
                                    allocation.errors.minAdultsPerRoom.isError
                                }
                                keyboardNavigationContainerId={
                                    'travel-form-room-allocation-desktop-counter-with-label-adults'
                                }
                                label={roomAllocation.configuration.limits.adults.label}
                                labelPosition={'space-between'}
                                max={roomAllocation.configuration.limits.adults.max}
                                min={roomAllocation.configuration.limits.adults.min}
                                onDecrease={() => removeAdult()}
                                onIncrease={() => addAdult()}
                            />
                            <CounterWithLabelDesktop
                                count={allocation.childrenCount}
                                disableIncrease={
                                    allocation.listOfChildren.length ===
                                        roomAllocation.configuration.limits.children.max ||
                                    allocation.childrenCount >=
                                        roomAllocation.configuration.limits.maxChildrenPerRoom * allocation.roomsCount +
                                            1
                                }
                                iconSize={38}
                                isLabelError={allocation.errors.exceedsMaxChildrenPerRoom.isError}
                                isValueError={allocation.errors.exceedsMaxChildrenPerRoom.isError}
                                keyboardNavigationContainerId={
                                    'travel-form-room-allocation-desktop-counter-with-label-children'
                                }
                                label={roomAllocation.configuration.limits.children.label}
                                labelPosition={'space-between'}
                                max={roomAllocation.configuration.limits.children.max}
                                min={roomAllocation.configuration.limits.children.min}
                                onDecrease={() => removeChild()}
                                onIncrease={() => addChild()}
                            />
                            <CounterWithLabelDesktop
                                count={allocation.roomsCount}
                                disableIncrease={
                                    allocation.roomsCount === roomAllocation.configuration.limits.rooms.max
                                }
                                iconSize={38}
                                keyboardNavigationContainerId={
                                    'travel-form-room-allocation-desktop-counter-with-label-rooms'
                                }
                                label={roomAllocation.configuration.limits.rooms.label}
                                labelPosition={'space-between'}
                                max={roomAllocation.configuration.limits.rooms.max}
                                min={roomAllocation.configuration.limits.rooms.min}
                                onDecrease={() => removeRoom()}
                                onIncrease={() => addRoom()}
                            />
                        </>
                    </div>
                )}
                {(allocation.errors.exceedsMaxAdultsPerRoom.isError ||
                    allocation.errors.minAdultsPerRoom.isError ||
                    allocation.errors.exceedsMaxChildrenPerRoom.isError) && (
                    <div className={styles.roomAllocationErrorTextcontainer}>
                        {allocation.errors.exceedsMaxAdultsPerRoom.isError && (
                            <div>
                                <div className={styles.errorText}>
                                    {allocation.errors.exceedsMaxAdultsPerRoom.errorText}
                                </div>
                                <div>{allocation.errors.exceedsMaxAdultsPerRoom.hintText}</div>
                            </div>
                        )}
                        {allocation.errors.exceedsMaxChildrenPerRoom.isError &&
                            !allocation.errors.exceedsMaxAdultsPerRoom.isError && (
                                <div>
                                    <div className={styles.errorText}>
                                        {allocation.errors.exceedsMaxChildrenPerRoom.errorText}
                                    </div>
                                    <div>{allocation.errors.exceedsMaxChildrenPerRoom.hintText}</div>
                                </div>
                            )}
                        {allocation.errors.minAdultsPerRoom.isError && (
                            <div>
                                <div className={styles.errorText}>{allocation.errors.minAdultsPerRoom.errorText}</div>
                                <div>{allocation.errors.minAdultsPerRoom.hintText}</div>
                            </div>
                        )}
                    </div>
                )}
                {!isCustomAllocationLayerOpen && allocation.listOfChildren.length > 0 && (
                    <TravelFormAgeAllocationDesktop
                        ageOptions={roomAllocation.configuration.ageOptions}
                        areAllChildrenAgesAllocated={!allocation.errors.doChildrenWithoutAgeExist}
                        handleOptionChange={allocateAge}
                        listOfChildren={allocation.listOfChildren}
                    />
                )}
                {!isCustomAllocationLayerOpen && isValidAllocation && allocation.roomsCount > 1 && (
                    <TravelFormAdultRoomAllocationPreview
                        rooms={allocation.rooms}
                        setRoomOverlayOpen={(isRoomOverlayOpen) => {
                            setCustomAllocationLayerOpen(isRoomOverlayOpen);
                        }}
                    />
                )}
                {isCustomAllocationLayerOpen && (
                    <TravelFormRoomAllocationLayerDesktop
                        adultsConfig={roomAllocation.configuration.limits.adults}
                        ageOptions={roomAllocation.configuration.ageOptions}
                        childrenConfig={roomAllocation.configuration.limits.children}
                        maxAdultsPerRoom={roomAllocation.configuration.limits.maxAdultsPerRoom}
                        maxChildrenPerRoom={roomAllocation.configuration.limits.maxChildrenPerRoom}
                        preSelectedRooms={roomAllocation.configuration.preSelectedRooms}
                        roomsConfig={roomAllocation.configuration.limits.rooms}
                    />
                )}
            </div>
        </div>
    );
}
