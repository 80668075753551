import type {
    TravelFormAgeOptions,
    TravelFormRoomConfiguration,
    TravelFormRooms,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import CounterWithLabelDesktop from '@/core/components/counter/desktop/counter-with-label-desktop';
import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import KeyboardNavigationItemDesktop from '@/core/components/dropdown/keyboard-navigation-item-desktop';
import TravelFormAgeAllocationDesktop from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/age-allocation/age-allocation-desktop/travel-form-room-age-allocation-desktop';
import {
    doChildrenWithoutAgeExist,
    getAdultsInRoom,
    getChildrenInRoom,
} from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-service';
import useTravelFormRoomAllocation from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/use-travel-form-room-allocation';

import styles from './travel-form-room-allocation-layer-desktop.module.scss';

type TravelFormRoomAllocationOverlayProps = {
    adultsConfig: TravelFormRoomConfiguration;
    ageOptions: TravelFormAgeOptions;
    childrenConfig: TravelFormRoomConfiguration;
    maxAdultsPerRoom: number;
    maxChildrenPerRoom: number;
    preSelectedRooms: TravelFormRooms;
    roomsConfig: TravelFormRoomConfiguration;
};

export default function TravelFormRoomAllocationLayerDesktop({
    adultsConfig,
    ageOptions,
    childrenConfig,
    maxAdultsPerRoom,
    maxChildrenPerRoom,
    preSelectedRooms,
    roomsConfig,
}: TravelFormRoomAllocationOverlayProps) {
    const addRoomButtonContainerRef = React.useRef<HTMLDivElement | null>(null);
    const lastRoomRef = React.useRef<HTMLDivElement | null>(null);

    const { addAdult, addChild, addRoom, allocateChildAgeInRoom, allocation, removeAdult, removeChild, removeRoom } =
        useTravelFormRoomAllocation(preSelectedRooms, maxAdultsPerRoom, maxChildrenPerRoom);

    const findNexElemnetWithTabIndex = () => {
        if (addRoomButtonContainerRef.current) {
            const focusableElements = Array.from(
                addRoomButtonContainerRef.current.querySelectorAll<HTMLElement>('[tabindex]:not([tabindex="-1"])'),
            );
            if (!(document.activeElement instanceof HTMLElement)) {
                return null;
            }
            const currentIndex = focusableElements.indexOf(document.activeElement);
            return focusableElements[(currentIndex + 1) % focusableElements.length];
        }
        return null;
    };
    const handleDeleteOnClick = (event: React.MouseEvent, index: number) => {
        if (index === allocation.rooms.length - 1) {
            const nextItem = findNexElemnetWithTabIndex();
            nextItem?.focus();
        }
        removeRoom(index);
    };

    const handleAddRoom = () => {
        if (lastRoomRef.current) {
            const focusableElements = Array.from(
                lastRoomRef.current.querySelectorAll<HTMLElement>('[tabindex]:not([tabindex="-1"])'),
            );
            //Possible improvement: if we could make the addRoom function get a prop "focusOnRoom" and if set true focus on it, the user be focused on the new room right away and it would look alot nicer but for now this works
            focusableElements[0]?.focus();
        }
        addRoom('with-adult');
    };

    const keyboardContainerStyle: React.CSSProperties = {
        borderRadius: '10px',
        display: 'inline-block',
        height: 'fit-content',
        outline: '10px solid #005ea8',
        outlineOffset: '4px',
        outlineWidth: '2px',
    };

    return (
        <div className={styles.layerContent}>
            {allocation.rooms.map((room, index) => {
                const adults = getAdultsInRoom(room);
                const children = getChildrenInRoom(room);

                return (
                    <div
                        className={styles.roomConfig}
                        key={index}
                        ref={index === allocation.roomsCount - 1 ? lastRoomRef : undefined}
                    >
                        <div className={'flex-space-between-horizontal'}>
                            <div className={styles.roomConfigHeaderTitle}>{`Zimmer ${index + 1}`}</div>
                            {index > 0 && (
                                <KeyboardNavigationContainerDesktop
                                    activeOnFocus={true}
                                    containerId={`travel-form-room-allocation-layer-desktop-remove-room-${index}`}
                                    containerStyleWhileActive={keyboardContainerStyle}
                                    hasCustomEvents={true}
                                >
                                    <KeyboardNavigationItemDesktop
                                        customEventKey={' '}
                                        name={'remove-room'}
                                        onSelect={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                                            handleDeleteOnClick(event, index)
                                        }
                                    >
                                        <button
                                            className={styles.roomConfigHeaderRemoveButton}
                                            data-qa-id={'qa-travel-form-rooms-remove-desktop'}
                                            onClick={(event) => handleDeleteOnClick(event, index)}
                                            tabIndex={-1}
                                        >
                                            entfernen
                                        </button>
                                    </KeyboardNavigationItemDesktop>
                                </KeyboardNavigationContainerDesktop>
                            )}
                        </div>
                        <CounterWithLabelDesktop
                            count={adults.length}
                            disableIncrease={adults.length === maxAdultsPerRoom}
                            isLabelError={
                                allocation.errors.exceedsMaxAdultsPerRoom.isError ||
                                allocation.errors.minAdultsPerRoom.isError
                            }
                            isValueError={
                                allocation.errors.exceedsMaxAdultsPerRoom.isError ||
                                allocation.errors.minAdultsPerRoom.isError
                            }
                            keyboardNavigationContainerId={
                                'travel-form-room-allocation-layer-desktop-counter-with-label-adults'
                            }
                            label={adultsConfig.label}
                            labelPosition={'space-between'}
                            max={maxAdultsPerRoom}
                            min={adultsConfig.min}
                            onDecrease={() => removeAdult(index)}
                            onIncrease={() => addAdult(index)}
                        />
                        <CounterWithLabelDesktop
                            count={children.length}
                            disableIncrease={children.length === maxChildrenPerRoom}
                            isLabelError={
                                allocation.errors.exceedsMaxChildrenPerRoom.isError ||
                                allocation.errors.exceedsMaxChildrenPerRoom.isError
                            }
                            isValueError={
                                allocation.errors.exceedsMaxChildrenPerRoom.isError ||
                                allocation.errors.exceedsMaxChildrenPerRoom.isError
                            }
                            keyboardNavigationContainerId={
                                'travel-form-room-allocation-layer-desktop-counter-with-label-children'
                            }
                            label={childrenConfig.label}
                            labelPosition={'space-between'}
                            max={maxChildrenPerRoom}
                            min={childrenConfig.min}
                            onDecrease={() => removeChild(index)}
                            onIncrease={() => addChild(index)}
                        />
                        {children.length > 0 && (
                            <TravelFormAgeAllocationDesktop
                                ageOptions={ageOptions}
                                areAllChildrenAgesAllocated={!doChildrenWithoutAgeExist(children)}
                                handleOptionChange={(childIndex, value) =>
                                    allocateChildAgeInRoom(index, adults, children, childIndex, value)
                                }
                                listOfChildren={children}
                            />
                        )}
                    </div>
                );
            })}
            {allocation.roomsCount < roomsConfig.max && (
                <div
                    className={styles.addRoomButtonContainer}
                    ref={addRoomButtonContainerRef}
                >
                    <KeyboardNavigationContainerDesktop
                        activeOnFocus={true}
                        containerId={'travel-form-room-allocation-layer-desktop-add-room'}
                        containerStyleWhileActive={keyboardContainerStyle}
                        hasCustomEvents={true}
                    >
                        <KeyboardNavigationItemDesktop
                            customEventKey={' '}
                            name={'room-assignment'}
                            onSelect={handleAddRoom}
                        >
                            <button
                                className={styles.addRoomButton}
                                data-qa-id={'qa-travel-form-room-add-desktop'}
                                onClick={() => addRoom('with-adult')}
                                tabIndex={-1}
                            >
                                + Zimmer hinzufügen
                            </button>
                        </KeyboardNavigationItemDesktop>
                    </KeyboardNavigationContainerDesktop>
                </div>
            )}
        </div>
    );
}
