import type {
    TravelFormDataV1Attributes,
    TravelFormDurationPreset,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type {
    ApplyTravelFormFilter,
    TravelFormDesktopFormConfiguration,
} from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';

import React from 'react';

import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import { logger } from '@/core/features/logger/logger';
import TravelFormInputWrapperDesktop from '@/features/travel-form/travel-form-desktop/travel-form-input-desktop/travel-form-input-desktop';
import TravelFormDurationDesktop from '@/features/travel-form/travel-form-overlay/travel-form-duration-overlay/travel-form-duration-overlay-desktop/travel-form-duration-desktop';
import useDurationOverlay from '@/features/travel-form/travel-form-overlay/travel-form-duration-overlay/use-duration-overlay';
import { useIsTravelFormDataLoading } from '@/features/travel-form/travel-form-state';

type TravelFormDurationOverlayContainerDesktopProps = {
    duration: TravelFormDataV1Attributes['filters']['duration'] | undefined;
    formConfiguration: TravelFormDesktopFormConfiguration;
    hasError: boolean;
    inputName: TravelFormOverlayName;
    isLastOverlay?: boolean;
    isOverlayVisible: boolean;
    onInputClick: (inputName: TravelFormOverlayName) => void;
    onOverlayClose: () => void;
    onOverlayCtaClick: (config: ApplyTravelFormFilter) => void;
    onTravelFormCtaClick?: () => void;
    openNextInputOverlay?: () => void;
};

export default function TravelFormDurationOverlayContainerDesktop({
    duration,
    formConfiguration,
    hasError,
    inputName,
    isLastOverlay = false,
    isOverlayVisible,
    onInputClick,
    onOverlayClose,
    onOverlayCtaClick,
    onTravelFormCtaClick,
    openNextInputOverlay,
}: TravelFormDurationOverlayContainerDesktopProps) {
    const {
        activeDurationPlaceholderText,
        activeModule,
        activePresetChipText,
        counter,
        dayRange,
        onRangeChange,
        resetDurationState,
        setActiveModule,
        setActivePresetChipText,
        setCounter,
    } = useDurationOverlay({ duration, mode: 'auto-update' });

    const isTravelFormDataLoading = useIsTravelFormDataLoading();

    const inputConfiguration = formConfiguration.fields?.duration;
    const inputLabel = duration?.inputLabel ?? inputConfiguration?.inputLabel;
    const placeholderText = duration?.placeholderText ?? inputConfiguration?.placeholderText;

    const [wasActivelyUsed, setWasActivelyUsed] = React.useState<boolean>(false);

    const handleOverlayCtaClick = React.useCallback(() => {
        if (!duration?.name) {
            return;
        }
        setWasActivelyUsed(true);

        switch (activeModule) {
            case 'preset':
                if (!activePresetChipText) {
                    return;
                }
                onOverlayCtaClick({ filterName: duration.name, filterValue: activePresetChipText });
                break;
            case 'counter':
                onOverlayCtaClick({ filterName: duration.name, filterValue: counter.toString() });
                break;
            case 'range':
                onOverlayCtaClick({ filterName: duration.name, filterValue: `${dayRange.min}-${dayRange.max}` });
                break;
            case 'exactDays':
                if (!duration.section.exactDays) {
                    return;
                }
                onOverlayCtaClick({ filterName: duration.name, filterValue: duration.section.exactDays.value });
                break;
            default:
                logger.error(`Could not apply filter because of unhandled duration activeModule: ${activeModule}.`);
                break;
        }
    }, [
        activeModule,
        activePresetChipText,
        counter,
        dayRange.max,
        dayRange.min,
        duration?.name,
        duration?.section.exactDays,
        onOverlayCtaClick,
    ]);

    const handlePresetChipClick = (presetChip: TravelFormDurationPreset, enableNextInputOverlay?: boolean) => {
        if (!duration?.name) {
            return;
        }

        setWasActivelyUsed(true);
        setActivePresetChipText(presetChip.value);
        onOverlayCtaClick({ filterName: duration?.name, filterValue: presetChip.value });
        if (!!enableNextInputOverlay) {
            openNextInputOverlay?.();
        }
    };

    const onCalendarPickClick = (exactDays: string) => {
        if (!duration?.name) {
            return;
        }

        setActivePresetChipText(exactDays);
        onOverlayCtaClick({ filterName: duration?.name, filterValue: exactDays });
    };

    const handleOverlayClose = () => {
        resetDurationState();
        onOverlayClose();
    };

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === ' ') {
                event.preventDefault();
                handleOverlayCtaClick();
                openNextInputOverlay?.();
            }
        };

        if (!isOverlayVisible) {
            document.removeEventListener('keydown', handleKeyDown);
            return;
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleOverlayCtaClick, isOverlayVisible, openNextInputOverlay]);

    return (
        <KeyboardNavigationContainerDesktop
            autoFocus={true}
            containerId={'travel-form-duration-overlay-container-desktop'}
            hasCustomEvents={true}
            hasNoKeyboardNavigationItem={true}
            isActive={isOverlayVisible}
            onCtaClick={() => {
                if (activeModule === 'preset') {
                    return;
                }
                handleOverlayCtaClick();
                setTimeout(() => {
                    onTravelFormCtaClick?.();
                }, 200);
            }}
            onFocus={() => {
                if (!isOverlayVisible) {
                    onInputClick(inputName);
                }
            }}
        >
            <TravelFormInputWrapperDesktop
                ctaText={duration?.ctaText ?? 'Daten übernehmen'}
                filterValue={
                    wasActivelyUsed
                        ? isTravelFormDataLoading && activeDurationPlaceholderText.length > 0
                            ? activeDurationPlaceholderText
                            : (placeholderText ?? null)
                        : null
                }
                hasError={hasError}
                inputSize={formConfiguration.inputSize}
                isLastOverlay={isLastOverlay}
                isOverlayVisible={isOverlayVisible}
                label={inputLabel ?? 'Reisedauer'}
                onChange={() => {}}
                onClick={() => onInputClick(inputName)}
                onClose={handleOverlayClose}
                onCtaClick={handleOverlayCtaClick}
                openNextInputOverlay={openNextInputOverlay}
                placeholder={isOverlayVisible ? activeDurationPlaceholderText : (placeholderText ?? 'Dauer der Reise')}
                qaId={'qa-travel-form-input-duration-desktop'}
                type={'cta'}
            >
                <TravelFormDurationDesktop
                    activeModule={activeModule}
                    activePresetChipText={activePresetChipText}
                    currentRangeValue={dayRange}
                    dayCounter={counter}
                    dayRangeMax={duration?.section.range.maxDays ?? 56}
                    dayRangeMin={duration?.section.range.minDays ?? 2}
                    exactDays={duration?.section.exactDays ?? null}
                    onActiveModuleChange={setActiveModule}
                    onCalendarPickClick={(value) => onCalendarPickClick(value)}
                    onDayCountDecrease={(nextCount) => setCounter(nextCount)}
                    onDayCountIncrease={(nextCount) => setCounter(nextCount)}
                    onPresetChipClick={handlePresetChipClick}
                    onRangeChange={onRangeChange}
                    onTravelFormCtaClick={onTravelFormCtaClick}
                    presetChips={duration?.section.preset.options ?? []}
                    rangeStep={duration?.section.range.steps ?? 1}
                />
            </TravelFormInputWrapperDesktop>
        </KeyboardNavigationContainerDesktop>
    );
}
