import type { TravelFormDesktopProps } from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormV1Attributes } from '@/features/travel-form/travel-form-v1/travel-form-v1-type';

import Banner from '@/core/components/banner/banner';
import { HtmlTextWrapper, IconImage } from '@/core/features';
import color from '@/core/features/styles/color';
import { classnames } from '@/core/utils/bem-classname';
import HeaderMapButtonDesktop from '@/features/map/header-map-button-desktop/header-map-button-desktop';
import TravelFormDesktop from '@/features/travel-form/travel-form-desktop/travel-form-desktop';

import styles from './travel-form-v1-desktop.module.scss';

type TravelFormV1DesktopProps = {
    banner: TravelFormV1Attributes['banner'];
    isWidget: boolean;
} & TravelFormDesktopProps;

export default function TravelFormV1Desktop({
    activityRequestTarget,
    airportRequestTarget,
    banner,
    dateRequestTarget,
    destinationChangeRequestTarget,
    destinationRequestTarget,
    formConfiguration,
    isSticky,
    isWidget,
    onOverlayClose,
    onOverlayCtaClick,
    openOverlay,
    overlayType,
    travelForm,
}: TravelFormV1DesktopProps) {
    const numberOfFields = Object.values(formConfiguration.fields || {}).filter((field) => !!field).length;
    if (!banner) {
        return (
            <TravelFormDesktop
                activityRequestTarget={activityRequestTarget}
                airportRequestTarget={airportRequestTarget}
                dateRequestTarget={dateRequestTarget}
                destinationChangeRequestTarget={destinationChangeRequestTarget}
                destinationRequestTarget={destinationRequestTarget}
                formConfiguration={formConfiguration}
                isSticky={isSticky}
                isWidget={isWidget}
                onOverlayClose={onOverlayClose}
                onOverlayCtaClick={onOverlayCtaClick}
                openOverlay={openOverlay}
                overlayType={overlayType}
                travelForm={travelForm}
            />
        );
    }

    return (
        <div className={'width-100'}>
            <Banner
                className={styles.seaBanner}
                height={banner.image ? 500 : 330}
                image={banner.image}
                imageOverlayColor={banner.image ? color('catalinaBlue-04') : undefined}
                marginBottom={banner.image ? -15 : 0}
            >
                <div
                    className={styles.contentWrapper}
                    style={{ maxWidth: numberOfFields > 2 ? '1280px' : '1072px' }}
                >
                    <div className={`${banner.image ? 'width-100' : 'flex-space-between-horizontal'}`}>
                        <div
                            className={classnames(
                                styles.textWrapper,
                                banner.htmlTitle ? 'flex-start-vertical' : 'flex-center-vertical',
                                banner.image ? 'text-white' : 'margin-top-30',
                            )}
                        >
                            {banner.htmlTitle ? (
                                <h1>
                                    <HtmlTextWrapper htmlText={banner.htmlTitle} />
                                </h1>
                            ) : (
                                banner.title && <h1 className={'bold'}>{banner.title}</h1>
                            )}
                            {banner.subtitle && <h2 className={'break-word font-size-18'}>{banner.subtitle}</h2>}
                        </div>
                        {!banner.signet && !banner.image && <div className={styles.fixedFallbackHeight}></div>}
                        {banner.signet && (
                            <div className={styles.signet}>
                                <IconImage
                                    alt={banner.signet.imageAlt}
                                    height={200}
                                    inline={true}
                                    url={banner.signet.imageUrl}
                                    width={200}
                                />
                            </div>
                        )}
                    </div>
                    <div className={'flex-center flex-column'}>
                        <TravelFormDesktop
                            activityRequestTarget={activityRequestTarget}
                            airportRequestTarget={airportRequestTarget}
                            dateRequestTarget={dateRequestTarget}
                            destinationChangeRequestTarget={destinationChangeRequestTarget}
                            destinationRequestTarget={destinationRequestTarget}
                            disableCtaTooltip={true}
                            formConfiguration={formConfiguration}
                            isSticky={isSticky}
                            isWidget={isWidget}
                            onOverlayClose={onOverlayClose}
                            onOverlayCtaClick={onOverlayCtaClick}
                            openOverlay={openOverlay}
                            overlayType={overlayType}
                            travelForm={travelForm}
                        />
                        {banner.mapButton && (
                            <div className={styles.mapButton}>
                                <HeaderMapButtonDesktop
                                    iconUrl={banner.mapButton.iconUrl}
                                    linkUrl={banner.mapButton.linkUrl}
                                    title={banner.mapButton.title}
                                    width={'160px'}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Banner>
            {!banner.image && <div className={styles.divider} />}
        </div>
    );
}
