import type { TravelFormDataV1Attributes } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { TravelFormFilterValue } from '@/features/travel-form/travel-form-data-v1/use-travel-form-data-v1';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';
import type { TravelFormField, TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';

import React from 'react';

import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import KeyboardNavigationItemDesktop from '@/core/components/dropdown/keyboard-navigation-item-desktop';
import { Button, Tooltip } from '@/core/features';
import { useRouterLink } from '@/core/features/router/router-link';
import useSticky from '@/core/features/scroll/use-sticky';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';
import useTravelForm from '@/features/travel-form/travel-form-expanded/use-travel-form';
import TravelFormActivityOverlayContainerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-activity-overlay/travel-form-activity-overlay-desktop/travel-form-activity-overlay-container-desktop';
import TravelFormAirportOverlayContainerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-airport-overlay/travel-form-airport-overlay-desktop/travel-form-airport-overlay-container-desktop';
import TravelFormDatePickerOverlayContainerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-date-picker-overlay/travel-form-date-picker-overlay-desktop/travel-form-date-picker-overlay-container-desktop';
import TravelFormDestinationOverlayContainerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-destination-overlay/travel-form-destination-overlay-desktop/travel-form-destination-overlay-container-desktop';
import TravelFormDurationOverlayContainerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-duration-overlay/travel-form-duration-overlay-desktop/travel-form-duration-overlay-container-desktop';
import TravelFormRoomAllocationContainerDesktop from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-overlay-desktop/travel-form-room-allocation-overlay-container-desktop';
import {
    useIsTravelFormDataLoading,
    useIsTravelFormNavigatingAtom,
    useTravelFormChanged,
} from '@/features/travel-form/travel-form-state';

import styles from './travel-form-desktop.module.scss';

const bem = bemModule(styles);

export type TravelFormDesktopFormConfiguration = {
    activeFilters: TravelFormDataV1Attributes['activeFilters'];
    cta: {
        dependsOn: TravelFormFilterName[];
        text: string;
    };
    fields: Record<keyof TravelFormDataV1Attributes['filters'], TravelFormField | null> | null;
    inputSize: 'large' | 'normal';
    noPadding?: boolean;
    onFormLoaded?: () => void;
    onSubmit: () => void;
    openOverlay: (overlayName: TravelFormOverlayName) => void;
    orientation: 'horizontal' | 'vertical';
};

export type TravelFormDesktopProps = {
    activityRequestTarget: string | undefined;
    airportRequestTarget: string | undefined;
    dateRequestTarget: string | undefined;
    destinationChangeRequestTarget: string | undefined;
    destinationRequestTarget: string | undefined;
    disableCtaTooltip?: boolean;
    formConfiguration: TravelFormDesktopFormConfiguration;
    isSticky?: boolean;
    isWidget: boolean;
    onOverlayClose: () => void;
    onOverlayCtaClick: (config: ApplyTravelFormFilter) => void;
    openOverlay: (inputName: TravelFormOverlayName) => void;
    overlayType: TravelFormOverlayName | null;
    travelForm: TravelFormDataV1Attributes | null;
};

export type ApplyTravelFormFilter = {
    filterName: TravelFormFilterName;
    filterValue: TravelFormFilterValue;
    requestTargetOverride?: string;
};

type FieldType = keyof TravelFormDataV1Attributes['filters'];

export default function TravelFormDesktop({
    activityRequestTarget,
    airportRequestTarget,
    dateRequestTarget,
    destinationChangeRequestTarget,
    destinationRequestTarget,
    disableCtaTooltip,
    formConfiguration,
    isSticky = false,
    isWidget,
    onOverlayClose,
    onOverlayCtaClick,
    openOverlay,
    overlayType,
    travelForm,
}: TravelFormDesktopProps) {
    const [hasTravelFormChanged, setHasTravelFormChanged] = useTravelFormChanged();
    const { ref: travelFormDesktopRef, stickyStyle } = useSticky(isSticky ? 'TravelFormDesktop' : null);
    const { navigate } = useRouterLink();
    const { activeFilters, fields, inputSize, onSubmit, orientation } = formConfiguration;
    const { isInputDisabled, isInputError, onInputClickHandler, onSubmitHandler } = useTravelForm({
        activeFilters,
        onSubmit,
        onTravelFormLoaded: () => {},
        openOverlay,
    });

    const [isCtaHighlighted, setIsCtaHighlighted] = React.useState(false);

    const onDestinationOverlayCtaClick = ({
        filterName,
        filterValue,
        requestTargetOverride,
    }: ApplyTravelFormFilter) => {
        onOverlayCtaClick({ filterName, filterValue, requestTargetOverride });
    };

    const [isKeyboardCtaClicked, setIsKeyboardCtaClicked] = React.useState(false);

    React.useEffect(() => {
        if (isKeyboardCtaClicked) {
            setIsKeyboardCtaClicked(false);
            onSubmitHandler(formConfiguration.cta.dependsOn);
        }
    }, [formConfiguration.cta.dependsOn, isKeyboardCtaClicked, onSubmitHandler]);

    React.useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Tab' && !event.shiftKey) {
                if (window.scrollY < 1) {
                    travelFormDesktopRef.current?.focus();
                }
            }
            document.removeEventListener('click', handleClick);
        };

        const handleClick = () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
        document.addEventListener('keydown', handleKeyPress, { once: true });
        document.addEventListener('mousedown', handleClick, { once: true });
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.removeEventListener('mousedown', handleClick);
        };
    }, [travelFormDesktopRef]);

    // The Api can tell us to directly redirect to the url they provided. This is the case for e.g. a destination change
    // to directly redirect from S2 to S1. Normally the user has to click the CTA
    React.useEffect(() => {
        if (!travelForm?.cta.linkUrl || !travelForm?.cta.redirectImmediately) {
            return;
        }

        navigate(travelForm?.cta.linkUrl);
    }, [travelForm?.cta.linkUrl, travelForm?.cta.redirectImmediately, navigate]);

    // Reset the hasTravelFormChanged state when mounting the component
    React.useEffect(() => {
        setHasTravelFormChanged(false);
    }, [setHasTravelFormChanged]);

    const fieldOrder: FieldType[] = ['activity', 'destination', 'airports', 'date', 'duration', 'roomAllocation'];
    const existingFieldsOrder = fieldOrder.filter((field) => !!fields?.[field]);

    const isTravelFormLoading = useIsTravelFormDataLoading();
    const [isTravelFormNavigating, _] = useIsTravelFormNavigatingAtom();

    return (
        <div
            className={bem(styles.travelFormDesktop, { [orientation]: true })}
            ref={travelFormDesktopRef}
            style={stickyStyle}
            tabIndex={-1}
        >
            <div className={bem(styles.inputWrapper, { [orientation]: true })}>
                {existingFieldsOrder.map((field, index) => {
                    const nextField = existingFieldsOrder[index + 1];
                    const openNextInputOverlay = nextField
                        ? () => onInputClickHandler(nextField, fields?.[nextField]?.dependsOn)
                        : undefined;

                    switch (field) {
                        case 'activity':
                            return (
                                fields?.activity && (
                                    <React.Fragment key={field}>
                                        <TravelFormActivityOverlayContainerDesktop
                                            activity={travelForm?.filters.activity}
                                            activityRequestTarget={activityRequestTarget}
                                            formConfiguration={formConfiguration}
                                            hasError={isInputError('activity')}
                                            inputName={'activity'}
                                            isLastOverlay={index + 1 === existingFieldsOrder.length}
                                            isOverlayVisible={overlayType === 'activity'}
                                            onInputClick={() =>
                                                onInputClickHandler('activity', fields?.activity?.dependsOn)
                                            }
                                            onOverlayClose={onOverlayClose}
                                            onOverlayCtaClick={onOverlayCtaClick}
                                            onTravelFormCtaClick={() => setIsKeyboardCtaClicked(true)}
                                            openNextInputOverlay={openNextInputOverlay}
                                        />
                                    </React.Fragment>
                                )
                            );
                        case 'destination':
                            return (
                                fields?.destination && (
                                    <React.Fragment key={field}>
                                        <TravelFormDestinationOverlayContainerDesktop
                                            destination={travelForm?.filters.destination}
                                            destinationChangeRequestTarget={destinationChangeRequestTarget}
                                            destinationRequestTarget={destinationRequestTarget}
                                            formConfiguration={formConfiguration}
                                            hasError={isInputError('destination')}
                                            inputName={'destination'}
                                            isInputDisabled={isInputDisabled(fields?.destination?.dependsOn)}
                                            isLastOverlay={index + 1 === existingFieldsOrder.length}
                                            isOverlayVisible={overlayType === 'destination'}
                                            onInputClick={() =>
                                                onInputClickHandler('destination', fields?.destination?.dependsOn)
                                            }
                                            onOverlayClose={onOverlayClose}
                                            onOverlayCtaClick={onDestinationOverlayCtaClick}
                                            onTravelFormCtaClick={() => setIsKeyboardCtaClicked(true)}
                                            openNextInputOverlay={openNextInputOverlay}
                                        />
                                    </React.Fragment>
                                )
                            );
                        case 'date':
                            return (
                                fields?.date && (
                                    <React.Fragment key={field}>
                                        <TravelFormDatePickerOverlayContainerDesktop
                                            date={travelForm?.filters.date}
                                            dateRequestTarget={dateRequestTarget}
                                            formConfiguration={formConfiguration}
                                            hasError={isInputError('date')}
                                            inputName={'date'}
                                            isInputDisabled={isInputDisabled(fields?.date?.dependsOn)}
                                            isLastOverlay={index + 1 === existingFieldsOrder.length}
                                            isOverlayVisible={overlayType === 'date'}
                                            onInputClick={() => onInputClickHandler('date', fields?.date?.dependsOn)}
                                            onOverlayClose={onOverlayClose}
                                            onOverlayCtaClick={onOverlayCtaClick}
                                            onTravelFormCtaClick={() => setIsKeyboardCtaClicked(true)}
                                            openNextInputOverlay={openNextInputOverlay}
                                        />
                                    </React.Fragment>
                                )
                            );
                        case 'airports':
                            return (
                                fields?.airports && (
                                    <React.Fragment key={field}>
                                        <TravelFormAirportOverlayContainerDesktop
                                            airports={travelForm?.filters.airports}
                                            airportsRequestTarget={airportRequestTarget}
                                            formConfiguration={formConfiguration}
                                            hasError={isInputError('airports')}
                                            inputName={'airports'}
                                            isLastOverlay={index + 1 === existingFieldsOrder.length}
                                            isOverlayVisible={overlayType === 'airports'}
                                            onInputClick={() => onInputClickHandler('airports')}
                                            onOverlayClose={onOverlayClose}
                                            onOverlayCtaClick={onOverlayCtaClick}
                                            onTravelFormCtaClick={() => setIsKeyboardCtaClicked(true)}
                                            openNextInputOverlay={openNextInputOverlay}
                                        />
                                    </React.Fragment>
                                )
                            );
                        case 'duration':
                            return (
                                fields?.duration && (
                                    <React.Fragment key={field}>
                                        <TravelFormDurationOverlayContainerDesktop
                                            duration={travelForm?.filters.duration}
                                            formConfiguration={formConfiguration}
                                            hasError={isInputError('duration')}
                                            inputName={'duration'}
                                            isLastOverlay={index + 1 === existingFieldsOrder.length}
                                            isOverlayVisible={overlayType === 'duration'}
                                            onInputClick={() => onInputClickHandler('duration')}
                                            onOverlayClose={onOverlayClose}
                                            onOverlayCtaClick={onOverlayCtaClick}
                                            onTravelFormCtaClick={() => setIsKeyboardCtaClicked(true)}
                                            openNextInputOverlay={openNextInputOverlay}
                                        />
                                    </React.Fragment>
                                )
                            );
                        case 'roomAllocation':
                            return (
                                fields?.roomAllocation && (
                                    <React.Fragment key={field}>
                                        <TravelFormRoomAllocationContainerDesktop
                                            formConfiguration={formConfiguration}
                                            hasError={isInputError('roomAllocation')}
                                            inputName={'roomAllocation'}
                                            isLastOverlay={index + 1 === existingFieldsOrder.length}
                                            isOverlayVisible={overlayType === 'roomAllocation'}
                                            onInputClick={() => onInputClickHandler('roomAllocation')}
                                            onOverlayClose={onOverlayClose}
                                            onOverlayCtaClick={onOverlayCtaClick}
                                            onTravelFormCtaClick={() => setIsKeyboardCtaClicked(true)}
                                            roomAllocation={travelForm?.filters.roomAllocation}
                                        />
                                    </React.Fragment>
                                )
                            );
                    }
                })}

                <Tooltip
                    content={<div className={'padding-10 font-size-12'}>{travelForm?.cta?.filterHintText}</div>}
                    customZIndex={'travelFormInputSearchTooltip'}
                    tippyConfig={{
                        offset: [0, -5],
                        placement: 'right',
                        popperOptions: {
                            modifiers: [{ name: 'preventOverflow', options: { mainAxis: false } }],
                        },
                    }}
                    visible={
                        !isWidget && hasTravelFormChanged && !disableCtaTooltip && !!travelForm?.cta?.filterHintText
                    }
                >
                    <KeyboardNavigationContainerDesktop
                        activeOnFocus={true}
                        containerId={'travel-form-cta'}
                        hasCustomEvents={true}
                        onBlur={() => setIsCtaHighlighted(false)}
                        onCtaClick={() => onSubmitHandler(formConfiguration.cta.dependsOn)}
                        onFocus={() => {
                            setIsCtaHighlighted(true);
                            onOverlayClose();
                        }}
                    >
                        <KeyboardNavigationItemDesktop
                            customEventKey={' '}
                            name={'travel-form-cta'}
                            onSelect={() => onSubmitHandler(formConfiguration.cta.dependsOn)}
                        >
                            <Button
                                disableTabIndex={true}
                                fontSize={inputSize === 'large' ? 18 : 16}
                                fontWeight={'bold'}
                                height={inputSize === 'large' ? 60 : 50}
                                isLoading={isTravelFormLoading && isTravelFormNavigating}
                                onClick={() => onSubmitHandler(formConfiguration.cta.dependsOn)}
                                qaId={'qa-travel-form-submit-button-desktop'}
                                style={{
                                    backgroundColor: isCtaHighlighted ? color('endeavour') : undefined,
                                    minWidth: inputSize === 'large' ? 188 : 'unset',
                                    width: inputSize === 'large' ? 188 : '100%',
                                }}
                                title={formConfiguration.cta.text}
                            />
                        </KeyboardNavigationItemDesktop>
                    </KeyboardNavigationContainerDesktop>
                </Tooltip>
            </div>
        </div>
    );
}
