import type { OnClickEvent } from '@/core/types/react-overrides';
import type { TravelFormActivityResult } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import CheckBox from '@/core/components/check-box/check-box';
import KeyboardNavigationItemDesktop from '@/core/components/dropdown/keyboard-navigation-item-desktop';
import {
    useActiveKeyboardItemName,
    useActiveKeyboardNavigationContainerIdAtom,
} from '@/core/components/dropdown/use-keyboard-navigation-container-desktop';
import { IconImage } from '@/core/features';
import color from '@/core/features/styles/color';
import { TravelFormActivityShimmerBoxDesktop } from '@/features/travel-form/travel-form-overlay/travel-form-shimmer-boxes-desktop';

import styles from './travel-form-activity-desktop.module.scss';

type TravelFormActivityDesktopProps = {
    activities: TravelFormActivityResult[];
    isLoading?: boolean;
    isMultiSelect: boolean;
    onActivityClick: (activityItem: TravelFormActivityResult) => void;
    selectedActivities: string[];
};

export default function TravelFormActivityDesktop({
    activities,
    isLoading,
    isMultiSelect,
    onActivityClick,
    selectedActivities,
}: TravelFormActivityDesktopProps) {
    const activeKeyboardItemName = useActiveKeyboardItemName();
    const activeKeyboardNavigationContainerId = useActiveKeyboardNavigationContainerIdAtom();

    if (isLoading) {
        return <TravelFormActivityShimmerBoxDesktop />;
    }

    const onClick = (event: OnClickEvent, activityItem: TravelFormActivityResult) => {
        event.stopPropagation();
        onActivityClick(activityItem);
    };
    return (
        <div className={styles.travelFormActivity}>
            {activities.map((activity) => (
                <KeyboardNavigationItemDesktop
                    key={activity.name}
                    name={activity.name}
                    onSelect={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => onClick(event, activity)}
                >
                    <div
                        className={styles.activityItem}
                        data-qa-id={'qa-travel-form-activity-item-desktop'}
                        onClick={(event) => onClick(event, activity)}
                        style={{
                            backgroundColor:
                                activeKeyboardItemName === activity.name &&
                                activeKeyboardNavigationContainerId === 'travel-form-activity-overlay-container-desktop'
                                    ? color('foam')
                                    : undefined,
                        }}
                    >
                        <div className={'flex gap-20 align-center'}>
                            <IconImage
                                disablePreload={true}
                                height={25}
                                url={activity.iconUrl}
                                width={25}
                            />
                            <div>{activity.title}</div>
                        </div>
                        {isMultiSelect ? (
                            <CheckBox
                                isSelected={selectedActivities.includes(activity.name)}
                                onClick={(event) => onClick(event, activity)}
                            />
                        ) : (
                            <div className={`flex-center ${styles.activityItemCount}`}>{activity.count}</div>
                        )}
                    </div>
                </KeyboardNavigationItemDesktop>
            ))}
        </div>
    );
}
