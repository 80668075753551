import type {
    TravelFormDestinationResult,
    TravelFormDestinationResultSetType,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import Divider from '@/core/components/divider/divider';
import useLoadingIndicator from '@/core/components/loading/use-loading-indicator';
import { LoadingSpinnerIcon } from '@/core/features';
import IconImage from '@/core/features/image-v2/icon-image';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';
import TravelFormDestinationResultSetDesktop from '@/features/travel-form/travel-form-overlay/travel-form-destination-overlay/travel-form-destination-overlay-desktop/travel-form-destination-result-list-desktop/travel-form-destination-result-set-desktop';
import { TravelFormSearchShimmerBoxDesktop } from '@/features/travel-form/travel-form-overlay/travel-form-shimmer-boxes-desktop';

import styles from './travel-form-destination-overlay-desktop.module.scss';

const bem = bemModule(styles);

type TravelFormDestinationProps = {
    isLoading: boolean;
    noResult: {
        iconUrl: string;
        text: string;
    } | null;
    onDestinationResultClick: (
        event: React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>,
        destination: TravelFormDestinationResult,
        enableNextInputOverlay?: boolean,
    ) => void;
    resultSet: TravelFormDestinationResultSetType[];
    searchQuery: string;
    selectedDestinationsByName: string[];
};

export default function TravelFormDestinationDesktop({
    isLoading,
    noResult,
    onDestinationResultClick,
    resultSet,
    searchQuery,
    selectedDestinationsByName,
}: TravelFormDestinationProps) {
    const showShimmerBoxLoading = resultSet.length === 0;
    const showLoadingIndicator = useLoadingIndicator(isLoading && !showShimmerBoxLoading);
    const [isHoveringAnyDestinationResult, setIsHoveringAnyDestinationResult] = React.useState(false);

    if (showShimmerBoxLoading) {
        return <TravelFormSearchShimmerBoxDesktop />;
    }

    return (
        <div className={bem(styles.overlayContent, { isLoading: isLoading || showLoadingIndicator })}>
            {noResult && (
                <>
                    <div className={`flex-space-between-horizontal ${styles.noResultWrapper}`}>
                        <div className={`line-clamp-2 ${styles.noResultText}`}>{noResult.text}</div>
                        <div className={styles.noResultIcon}>
                            <IconImage
                                alt={'no-result-icon'}
                                height={30}
                                url={noResult.iconUrl}
                                width={30}
                            />
                        </div>
                    </div>
                    <Divider
                        color={'gallery'}
                        marginBottom={20}
                        marginLeft={20}
                        marginRight={20}
                        marginTop={20}
                    />
                </>
            )}
            {resultSet.map((result, index) => (
                <React.Fragment key={`${result.title ?? 'destination-fallback-title'}-${index}`}>
                    <TravelFormDestinationResultSetDesktop
                        isFirstResultSet={index === 0}
                        isHoveringAnyDestinationResult={isHoveringAnyDestinationResult}
                        onResultClick={onDestinationResultClick}
                        resultSet={result}
                        searchQuery={searchQuery}
                        selectedDestinationsByName={selectedDestinationsByName}
                        setIsHoveringAnyDestinationResult={setIsHoveringAnyDestinationResult}
                    />
                    {index !== resultSet.length - 1 && (
                        <Divider
                            color={'gallery'}
                            marginBottom={20}
                            marginLeft={20}
                            marginRight={20}
                        />
                    )}
                </React.Fragment>
            ))}
            {showLoadingIndicator && (
                <div className={styles.loadingSpinner}>
                    <LoadingSpinnerIcon
                        color={color('doveGray')}
                        height={41}
                        width={41}
                    />
                </div>
            )}
        </div>
    );
}
