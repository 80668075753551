import type {
    TravelFormDataV1Attributes,
    TravelFormDestinationResult,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type {
    ApplyTravelFormFilter,
    TravelFormDesktopFormConfiguration,
} from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';

import React from 'react';

import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import { useActiveKeyboardItemName } from '@/core/components/dropdown/use-keyboard-navigation-container-desktop';
import { useAtomValue } from '@/core/features/store/atom-store';
import { mapDestinationOverrideAtom } from '@/features/map/map-data-v5/use-map-data-v5';
import TravelFormInputWrapperDesktop from '@/features/travel-form/travel-form-desktop/travel-form-input-desktop/travel-form-input-desktop';
import TravelFormDestinationDesktop from '@/features/travel-form/travel-form-overlay/travel-form-destination-overlay/travel-form-destination-overlay-desktop/travel-form-destination-overlay-desktop';
import useTravelFormDestinationOverlay from '@/features/travel-form/travel-form-overlay/travel-form-destination-overlay/use-travel-form-destination-overlay';
import { useResetDestinationListener } from '@/features/travel-form/use-reset-destination-listener';

type TravelFormDestinationDesktopContainerProps = {
    destination: TravelFormDataV1Attributes['filters']['destination'] | undefined;
    destinationChangeRequestTarget: string | undefined;
    destinationRequestTarget: string | undefined;
    formConfiguration: TravelFormDesktopFormConfiguration;
    hasError: boolean;
    inputName: TravelFormOverlayName;
    isInputDisabled: boolean;
    isLastOverlay?: boolean;
    isOverlayVisible: boolean;
    onInputClick: (inputName: TravelFormOverlayName) => void;
    onOverlayClose: () => void;
    onOverlayCtaClick: (config: ApplyTravelFormFilter) => void;
    onTravelFormCtaClick?: () => void;
    openNextInputOverlay?: () => void;
};

export default function TravelFormDestinationOverlayContainerDesktop({
    destination,
    destinationChangeRequestTarget,
    destinationRequestTarget,
    formConfiguration,
    hasError,
    inputName,
    isInputDisabled,
    isLastOverlay = false,
    isOverlayVisible,
    onInputClick,
    onOverlayClose,
    onOverlayCtaClick,
    onTravelFormCtaClick,
    openNextInputOverlay,
}: TravelFormDestinationDesktopContainerProps) {
    const {
        getDestinationListString,
        hasSelectionChanged,
        initialDestinationFilter,
        isLoading,
        onDestinationResultClick,
        resetSearch,
        result,
        searchDestination,
        searchQuery,
        selectedDestinations,
    } = useTravelFormDestinationOverlay({
        destinationRequestTarget: destinationRequestTarget ?? null,
        queryConfig: {
            enabled: isOverlayVisible,
        },
        selectedItems: destination?.selectedItems,
        selectionType: destination?.selectionType,
    });

    useResetDestinationListener();

    const isSingleSelect = destination?.selectionType === 'single';
    const selectedDestinationsByName = isSingleSelect
        ? []
        : selectedDestinations.map((destination) => destination.name);

    const initialSelectedItems = initialDestinationFilter?.selectedItems;

    const inputConfiguration = formConfiguration.fields?.destination;
    const isDataIncomplete = !destination || !destinationChangeRequestTarget || !destinationRequestTarget;
    const ctaText = selectedDestinations.length > 1 ? destination?.ctaText.plural : destination?.ctaText.singular;
    const inputLabel = destination?.inputLabel ?? inputConfiguration?.inputLabel;
    const placeholderText = destination?.placeholderText ?? inputConfiguration?.placeholderText;
    const mapDestinationOverride = useAtomValue(mapDestinationOverrideAtom);

    const optimisticPlaceholder = React.useMemo((): string | undefined => {
        if (mapDestinationOverride) {
            return mapDestinationOverride;
        }

        if (selectedDestinations.length === 1 && selectedDestinations[0] && selectedDestinations[0].isDefault) {
            return;
        }

        if (selectedDestinations.length === 0) {
            if (initialSelectedItems?.length === 1 && initialSelectedItems[0]?.isDefault) {
                return;
            }
            return placeholderText;
        }

        return selectedDestinations
            .reduce<string[]>((acc, destination) => {
                return destination.isDefault ? acc : [...acc, destination.title];
            }, [])
            .sort((destination1, destination2) => destination1.localeCompare(destination2))
            .join(', ');
    }, [initialSelectedItems, mapDestinationOverride, placeholderText, selectedDestinations]);

    const handleOverlayCtaClick = () => {
        if (!destination?.name) {
            return;
        }

        if (!hasSelectionChanged(selectedDestinations)) {
            return;
        }

        const listOfDestinations = getDestinationListString();
        if (!listOfDestinations.length) {
            onOverlayCtaClick({
                filterName: destination.name,
                filterValue: null,
                requestTargetOverride: destinationChangeRequestTarget,
            });
            return;
        }
        onOverlayCtaClick({
            filterName: destination.name,
            filterValue: listOfDestinations,
            requestTargetOverride: destinationChangeRequestTarget,
        });
        resetSearch();
    };

    const handleDestinationResultClick = (
        event: React.MouseEvent<HTMLButtonElement | HTMLSpanElement, MouseEvent>,
        selectedDestination: TravelFormDestinationResult,
        enableNextInputOverlay?: boolean,
    ) => {
        onDestinationResultClick(selectedDestination, event, (destinationsListString) => {
            if (!destination?.name) {
                return;
            }
            onOverlayCtaClick({
                filterName: destination.name,
                filterValue: destinationsListString,
                requestTargetOverride: destinationChangeRequestTarget,
            });
            if (!!enableNextInputOverlay && destination.selectionType !== 'multiple') {
                openNextInputOverlay?.();
            }
        });
    };

    const handleClose = () => {
        resetSearch();
        onOverlayClose();
    };

    const onInputChange = (value: string) => {
        if (!destinationRequestTarget) {
            return;
        }
        searchDestination(value, destinationRequestTarget);
    };

    const activeKeyboardItemName = useActiveKeyboardItemName();

    return (
        <KeyboardNavigationContainerDesktop
            autoFocus={true}
            className={'flex-column width-100 relative row-gap-1'}
            containerId={'travel-form-destination-overlay-container-desktop'}
            isActive={isOverlayVisible}
            onCtaClick={() => {
                if (isSingleSelect) {
                    const highlightedItem = result?.resultSet
                        .flatMap((set) => set.results)
                        .find((item) => item.name === activeKeyboardItemName);
                    if (highlightedItem) {
                        onDestinationResultClick(highlightedItem, undefined, (destinationsListString) => {
                            if (!destination?.name) {
                                return;
                            }
                            onOverlayCtaClick({
                                filterName: destination.name,
                                filterValue: destinationsListString,
                                requestTargetOverride: destinationChangeRequestTarget,
                            });
                        });
                    }
                }
                handleOverlayCtaClick();

                onTravelFormCtaClick?.();
            }}
            onFocus={() => {
                if (!isOverlayVisible) {
                    onInputClick(inputName);
                }
            }}
            skipFirstNavigationItemInRow={!!searchQuery}
        >
            <TravelFormInputWrapperDesktop
                autoFocus={true}
                ctaText={ctaText ?? 'Reiseziel übernehmen'}
                filterValue={optimisticPlaceholder ?? destination?.placeholderText ?? null}
                hasError={hasError}
                hideCta={isSingleSelect}
                inputSize={formConfiguration.inputSize}
                isInputDisabled={!isOverlayVisible || isInputDisabled}
                isLastOverlay={isLastOverlay}
                isLoading={isLoading || isDataIncomplete}
                isOverlayVisible={isOverlayVisible}
                label={inputLabel ?? 'Reiseziel'}
                onChange={onInputChange}
                onClear={resetSearch}
                onClick={() => onInputClick(inputName)}
                onClose={handleClose}
                onCtaClick={handleOverlayCtaClick}
                placeholder={
                    isOverlayVisible
                        ? (optimisticPlaceholder ?? 'Spot, Region, Land')
                        : (optimisticPlaceholder ?? placeholderText ?? 'Spot, Region, Land')
                }
                qaId={`qa-travel-form-input-destination-desktop${!isDataIncomplete ? '-loaded' : ''}`}
                type={'input'}
            >
                <TravelFormDestinationDesktop
                    isLoading={isLoading || isDataIncomplete}
                    noResult={searchQuery.length > 0 && result?.noResult ? result.noResult : null}
                    onDestinationResultClick={handleDestinationResultClick}
                    resultSet={result?.resultSet ?? []}
                    searchQuery={searchQuery}
                    selectedDestinationsByName={selectedDestinationsByName}
                />
            </TravelFormInputWrapperDesktop>
        </KeyboardNavigationContainerDesktop>
    );
}
