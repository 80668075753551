import type {
    TravelFormActivityResult,
    TravelFormDataV1Attributes,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type {
    ApplyTravelFormFilter,
    TravelFormDesktopFormConfiguration,
} from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';
import type { TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';

import React from 'react';

import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import TravelFormInputWrapperDesktop from '@/features/travel-form/travel-form-desktop/travel-form-input-desktop/travel-form-input-desktop';
import TravelFormActivityDesktop from '@/features/travel-form/travel-form-overlay/travel-form-activity-overlay/travel-form-activity-overlay-desktop/travel-form-activity-desktop';
import useTravelFormActivityOverlay from '@/features/travel-form/travel-form-overlay/travel-form-activity-overlay/use-travel-form-activity-overlay';

type TravelFormActivityOverlayContainerDesktopProps = {
    activity: TravelFormDataV1Attributes['filters']['activity'] | undefined;
    activityRequestTarget: string | undefined;
    formConfiguration: TravelFormDesktopFormConfiguration;
    hasError: boolean;
    inputName: TravelFormOverlayName;
    isLastOverlay?: boolean;
    isOverlayVisible: boolean;
    onInputClick: (inputName: TravelFormOverlayName, dependsOn?: TravelFormFilterName[]) => void;
    onOverlayClose: () => void;
    onOverlayCtaClick: (config: ApplyTravelFormFilter) => void;
    onTravelFormCtaClick?: () => void;
    openNextInputOverlay?: () => void;
};

export default function TravelFormActivityOverlayContainerDesktop({
    activity,
    activityRequestTarget,
    formConfiguration,
    hasError,
    inputName,
    isLastOverlay = false,
    isOverlayVisible,
    onInputClick,
    onOverlayClose,
    onOverlayCtaClick,
    onTravelFormCtaClick,
    openNextInputOverlay,
}: TravelFormActivityOverlayContainerDesktopProps) {
    const { isLoading, onActivityClick, results, selectedActivities, selectedActivitiesByName } =
        useTravelFormActivityOverlay({
            activityRequestTarget,
            isOverlayVisible,
            selectedItems: activity?.selectedItems,
            selectionType: activity?.selectionType,
        });

    const inputConfiguration = formConfiguration.fields?.activity;
    const inputLabel = activity?.inputLabel ?? inputConfiguration?.inputLabel;
    const placeholderText = activity?.placeholderText ?? inputConfiguration?.placeholderText;

    const handleActivityClick = (activityItem: TravelFormActivityResult) => {
        if (!activity?.name) {
            return;
        }
        onActivityClick(activityItem, () =>
            onOverlayCtaClick({ filterName: activity.name, filterValue: activityItem.name }),
        );
        if (activity.selectionType === 'single') {
            onOverlayClose();
        }
    };

    const handleOnInputClick = () => {
        if (isOverlayVisible) {
            onOverlayClose();
            return;
        }
        onInputClick(inputName);
    };

    const onSubmit = () => {
        if (!activity?.name) {
            return;
        }
        if (selectedActivities.length === 0) {
            onOverlayCtaClick({ filterName: activity.name, filterValue: null });
            return;
        }
        onOverlayCtaClick({ filterName: activity.name, filterValue: selectedActivitiesByName.join(',') });
    };

    return (
        <KeyboardNavigationContainerDesktop
            autoFocus={true}
            className={'width-100'}
            containerId={'travel-form-activity-overlay-container-desktop'}
            isActive={isOverlayVisible}
            onCtaClick={() => {
                onSubmit();
                setTimeout(() => {
                    onTravelFormCtaClick?.();
                }, 200);
            }}
            onFocus={() => {
                if (!isOverlayVisible) {
                    onInputClick(inputName);
                }
            }}
        >
            <TravelFormInputWrapperDesktop
                ctaText={
                    (selectedActivities.length > 1 ? activity?.ctaText.plural : activity?.ctaText.singular) ??
                    'Aktivität übernehmen'
                }
                errorLabel={inputConfiguration?.error?.text}
                filterValue={placeholderText ?? 'Aktivität'}
                hasError={hasError}
                inputSize={formConfiguration.inputSize}
                isInputDisabled={!isOverlayVisible}
                isLastOverlay={isLastOverlay}
                isOverlayVisible={isOverlayVisible}
                label={inputLabel ?? 'Aktivität'}
                maxHeight={414}
                onClick={handleOnInputClick}
                onClose={onOverlayClose}
                onCtaClick={onSubmit}
                onOutsideClick={onSubmit}
                openNextInputOverlay={openNextInputOverlay}
                placeholder={placeholderText ?? 'Aktivität'}
                qaId={`qa-travel-form-input-activity-desktop${isLoading ? '-loading' : ''}`}
                type={'dropdown'}
            >
                <TravelFormActivityDesktop
                    activities={results ?? []}
                    isLoading={isLoading}
                    isMultiSelect={activity?.selectionType === 'multiple'}
                    onActivityClick={handleActivityClick}
                    selectedActivities={selectedActivitiesByName}
                />
            </TravelFormInputWrapperDesktop>
        </KeyboardNavigationContainerDesktop>
    );
}
