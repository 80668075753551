import type {
    TravelFormAirportResult,
    TravelFormDataV1Attributes,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type {
    ApplyTravelFormFilter,
    TravelFormDesktopFormConfiguration,
} from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';

import React from 'react';

import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import TravelFormInputWrapperDesktop from '@/features/travel-form/travel-form-desktop/travel-form-input-desktop/travel-form-input-desktop';
import TravelFormAirportDesktop from '@/features/travel-form/travel-form-overlay/travel-form-airport-overlay/travel-form-airport-overlay-desktop/travel-form-airport-desktop';
import useTravelFormAirportOverlay from '@/features/travel-form/travel-form-overlay/travel-form-airport-overlay/use-travel-form-airport-overlay';

type TravelFormAirportOverlayContainerDesktopProps = {
    airports: TravelFormDataV1Attributes['filters']['airports'] | undefined;
    airportsRequestTarget: string | undefined;
    formConfiguration: TravelFormDesktopFormConfiguration;
    hasError: boolean;
    inputName: TravelFormOverlayName;
    isLastOverlay?: boolean;
    isOverlayVisible: boolean;
    onInputClick: (inputName: TravelFormOverlayName) => void;
    onOverlayClose: () => void;
    onOverlayCtaClick: (config: ApplyTravelFormFilter) => void;
    onTravelFormCtaClick?: () => void;
    openNextInputOverlay?: () => void;
};

export default function TravelFormAirportOverlayContainerDesktop({
    airports,
    airportsRequestTarget,
    formConfiguration,
    hasError,
    inputName,
    isLastOverlay = false,
    isOverlayVisible,
    onInputClick,
    onOverlayClose,
    onOverlayCtaClick,
    onTravelFormCtaClick,
    openNextInputOverlay,
}: TravelFormAirportOverlayContainerDesktopProps) {
    const {
        defaultAirport,
        isLoading,
        onAirportClick,
        resetSearch,
        result,
        searchAirport,
        searchQuery,
        selectedAirports,
        wasActivelyUsed,
    } = useTravelFormAirportOverlay(airportsRequestTarget ?? null, airports?.selectedItems, {
        enabled: isOverlayVisible,
    });

    const inputConfiguration = formConfiguration.fields?.airports;
    const isDataIncomplete = !airports || !airportsRequestTarget;
    const inputLabel = airports?.inputLabel ?? inputConfiguration?.inputLabel;
    const ctaText = selectedAirports.length > 1 ? airports?.ctaTexts.plural : airports?.ctaTexts.singular;
    const selectedAirportsPlaceholder = selectedAirports
        .reduce<string[]>((acc, airport) => {
            return airport.isDefault ? acc : [...acc, airport.name];
        }, [])
        .sort((airport1, airport2) => airport1.localeCompare(airport2))
        .join(', ');

    const handleOverlayCtaClick = () => {
        if (!airports?.name) {
            return;
        }
        const filteredSelectedAirports = selectedAirports
            .filter((airport) => !airport.isDefault)
            .map((airport) => airport.code);
        const listOfAirports = !filteredSelectedAirports.length ? null : filteredSelectedAirports.join(',');
        onOverlayCtaClick({
            filterName: airports.name,
            filterValue: listOfAirports,
            requestTargetOverride: airportsRequestTarget,
        });
        resetSearch(selectedAirports);
    };

    const handleClose = () => {
        onOverlayClose();
        resetSearch(selectedAirports);
    };

    const handleAirportClick = (
        selectedAirport: TravelFormAirportResult,
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
        enableNextInputOverlay?: boolean,
    ) => {
        onAirportClick(selectedAirport, event, () => {
            if (!airports?.name) {
                return;
            }
            onOverlayCtaClick({
                filterName: airports.name,
                filterValue: null,
                requestTargetOverride: airportsRequestTarget,
            });
            onOverlayClose();
            if (selectedAirport.isDefault && enableNextInputOverlay) {
                openNextInputOverlay?.();
            }
        });
    };

    const onInputChange = (value: string) => {
        if (!airportsRequestTarget) {
            return;
        }
        searchAirport(value, airportsRequestTarget);
    };

    const optimisticPlaceholder = React.useMemo(() => {
        if (selectedAirportsPlaceholder.length > 0) {
            return selectedAirportsPlaceholder;
        }

        if (defaultAirport) {
            return `${defaultAirport?.name} - ${defaultAirport?.code}`;
        }

        return null;
    }, [defaultAirport, selectedAirportsPlaceholder]);

    return (
        <KeyboardNavigationContainerDesktop
            autoFocus={true}
            containerId={'travel-form-airport-overlay-container-desktop'}
            isActive={isOverlayVisible}
            onCtaClick={() => {
                handleOverlayCtaClick();
                setTimeout(() => {
                    onTravelFormCtaClick?.();
                }, 200);
            }}
            onFocus={() => {
                if (!isOverlayVisible) {
                    onInputClick(inputName);
                }
            }}
        >
            <TravelFormInputWrapperDesktop
                autoFocus={true}
                ctaText={ctaText ?? 'Daten übernehmen'}
                filterValue={wasActivelyUsed ? optimisticPlaceholder : (airports?.placeholderText ?? null)}
                hasError={hasError}
                inputSize={formConfiguration.inputSize}
                isInputDisabled={!isOverlayVisible}
                isLastOverlay={isLastOverlay}
                isOverlayVisible={isOverlayVisible}
                label={inputLabel ?? 'Abflughafen'}
                onChange={onInputChange}
                onClear={() => resetSearch(selectedAirports)}
                onClick={() => onInputClick(inputName)}
                onClose={handleClose}
                onCtaClick={handleOverlayCtaClick}
                openNextInputOverlay={openNextInputOverlay}
                placeholder={
                    optimisticPlaceholder ?? formConfiguration.fields?.airports?.placeholderText ?? 'Abflughafen'
                }
                qaId={'qa-travel-form-input-airports-desktop'}
                type={'input'}
            >
                <TravelFormAirportDesktop
                    activeFiltersTitle={airports?.activeFiltersTitle}
                    defaultAirport={defaultAirport ?? null}
                    hasSearchQuery={searchQuery.length > 0}
                    isLoading={isLoading || isDataIncomplete}
                    noResult={searchQuery.length > 0 && result?.noResult ? result.noResult : null}
                    onAirportClick={handleAirportClick}
                    resultSet={result?.resultSet ?? []}
                    selectedAirports={selectedAirports}
                />
            </TravelFormInputWrapperDesktop>
        </KeyboardNavigationContainerDesktop>
    );
}
