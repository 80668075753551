/* https://benhoneywill.com/building-a-range-slider-component-in-react/ */

import React from 'react';

import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import KeyboardNavigationItemDesktop from '@/core/components/dropdown/keyboard-navigation-item-desktop';

import styles from '../range-slider.module.scss';

type RangeSliderProps = {
    disableTabIndex?: boolean;
    keyboardNavigationContainerId: string;
    max: number;
    min: number;
    onChange: (values: { max: number; min: number }) => void;
    onKeyboardActive?: () => void;
    step?: number;
    value: { max: number; min: number };
};

/** In short: we have two input controls that determine the min and max values.
 * These two inputs are made invisible and replaced by the controlWrapper
 * since we only need them to determine the two min & max values.
 * We then take the  min & max values of the inputs to set
 * the position of the nodes in css of the controlWrapper which replaces the two inputs.  */
export default function RangeSliderDesktop({
    disableTabIndex = false,
    keyboardNavigationContainerId,
    max,
    min,
    onChange,
    onKeyboardActive,
    step = 1,
    value,
}: RangeSliderProps) {
    const railRef = React.useRef<HTMLDivElement | null>(null);
    const rangeMiddle = max - min;
    const [minValue, setMinValue] = React.useState(value ? value.min : min);
    const [maxValue, setMaxValue] = React.useState(value ? value.max : max);

    const [isMinControlHighlighted, setIsMinControlHighlighted] = React.useState(false);
    const [isMaxControlHighlighted, setIsMaxControlHighlighted] = React.useState(false);

    const minControlNodePosition = ((minValue - min) / rangeMiddle) * 100;
    const maxControlNodePosition = ((maxValue - min) / rangeMiddle) * 100;

    React.useEffect(() => {
        if (value) {
            setMinValue(value.min);
            setMaxValue(value.max);
        }
    }, [value]);

    const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const newMinVal = Math.min(parseInt(event.target.value), maxValue - step);
        if (!value) {
            setMinValue(newMinVal);
        }
        onChange({ max: maxValue, min: newMinVal });
    };

    const minChangeByKeyboard = (amount: number) => {
        const newMinVal = Math.max(min, Math.min(minValue + amount, maxValue - step));
        if (!value) {
            setMinValue(newMinVal);
        }
        onChange({ max: maxValue, min: newMinVal });
    };

    const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const newMaxVal = Math.max(parseInt(event.target.value), minValue + step);
        if (!value) {
            setMaxValue(newMaxVal);
        }
        onChange({ max: newMaxVal, min: minValue });
    };

    const maxChangeByKeyboard = (amount: number) => {
        const newMaxVal = Math.min(max, Math.max(maxValue + amount, minValue + step));
        if (!value) {
            setMaxValue(newMaxVal);
        }
        onChange({ max: newMaxVal, min: minValue });
    };

    return (
        <div className={styles.rangeSlider}>
            <div className={styles.inputWrapper}>
                <input
                    className={styles.input}
                    max={max}
                    min={min}
                    onChange={handleMinChange}
                    step={step}
                    tabIndex={disableTabIndex ? -1 : undefined}
                    type={'range'}
                    value={minValue}
                />
                <input
                    className={styles.input}
                    max={max}
                    min={min}
                    onChange={handleMaxChange}
                    step={step}
                    tabIndex={disableTabIndex ? -1 : undefined}
                    type={'range'}
                    value={maxValue}
                />
            </div>
            <div className={styles.controlWrapper}>
                <KeyboardNavigationContainerDesktop
                    activeOnFocus={true}
                    containerId={`${keyboardNavigationContainerId}-min`}
                    hasCustomEvents={true}
                    onBlur={() => {
                        setIsMinControlHighlighted(false);
                    }}
                    onFocus={() => {
                        onKeyboardActive?.();
                        setIsMinControlHighlighted(true);
                    }}
                >
                    <div
                        className={styles.control}
                        style={{
                            border: isMinControlHighlighted ? '1px solid #005ea8' : undefined,
                            left: `${minControlNodePosition}%`,
                        }}
                    >
                        <KeyboardNavigationItemDesktop
                            customEventKey={'ArrowLeft'}
                            name={'decrease'}
                            onSelect={() => {
                                minChangeByKeyboard(-step);
                            }}
                        />
                        <KeyboardNavigationItemDesktop
                            customEventKey={'ArrowRight'}
                            name={'increase'}
                            onSelect={() => {
                                minChangeByKeyboard(step);
                            }}
                        />
                    </div>
                </KeyboardNavigationContainerDesktop>

                <div
                    className={styles.rail}
                    ref={railRef}
                >
                    <div
                        className={styles.innerRail}
                        style={{
                            left: `${minControlNodePosition}%`,
                            right: `100 - ${maxControlNodePosition}%`,
                            width: `${maxControlNodePosition - minControlNodePosition}%`,
                        }}
                    />
                </div>
                <KeyboardNavigationContainerDesktop
                    activeOnFocus={true}
                    containerId={`${keyboardNavigationContainerId}-max`}
                    hasCustomEvents={true}
                    onBlur={() => {
                        setIsMaxControlHighlighted(false);
                    }}
                    onFocus={() => {
                        onKeyboardActive?.();
                        setIsMaxControlHighlighted(true);
                    }}
                >
                    <div
                        className={styles.control}
                        style={{
                            border: isMaxControlHighlighted ? '1px solid #005ea8' : undefined,
                            left: `${maxControlNodePosition}%`,
                        }}
                    >
                        <KeyboardNavigationItemDesktop
                            customEventKey={'ArrowLeft'}
                            name={'decrease'}
                            onSelect={() => {
                                maxChangeByKeyboard(-step);
                            }}
                        />
                        <KeyboardNavigationItemDesktop
                            customEventKey={'ArrowRight'}
                            name={'increase'}
                            onSelect={() => {
                                maxChangeByKeyboard(step);
                            }}
                        />
                    </div>
                </KeyboardNavigationContainerDesktop>
            </div>
        </div>
    );
}
