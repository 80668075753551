import React from 'react';

import { MinusIcon } from '@/assets/icons/minus';
import { PlusIcon } from '@/assets/icons/plus';
import { decrement, increment } from '@/core/components/counter/counter-service';
import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import KeyboardNavigationItemDesktop from '@/core/components/dropdown/keyboard-navigation-item-desktop';
import color from '@/core/features/styles/color';

import styles from '../counter.module.scss';

type CounterProps = {
    count: number;
    disableActiveState?: boolean;
    disableTabIndex?: boolean;
    keyboardNavigationContainerId: string;
    max?: number;
    min?: number;
    onDecrease: (nextCount: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onIncrease: (nextCount: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onKeyboardActive?: () => void;
    steps?: number;
};

export default function CounterDesktop({
    count,
    disableActiveState = false,
    disableTabIndex = false,
    keyboardNavigationContainerId,
    max,
    min = 0,
    onDecrease,
    onIncrease,
    onKeyboardActive,
    steps = 1,
}: CounterProps) {
    const isMinusIconActive = !disableActiveState && count > min;
    const isPlusIconActive = !disableActiveState && max && count < max;

    return (
        <KeyboardNavigationContainerDesktop
            activeOnFocus={true}
            containerId={keyboardNavigationContainerId}
            hasCustomEvents={true}
            onFocus={onKeyboardActive}
        >
            <div className={`flex-space-between-horizontal ${styles.counter}`}>
                <button
                    className={styles.button}
                    data-qa-id={'qa-minus-button'}
                    onClick={(event) => decrement(event, count, steps, min, onDecrease)}
                    tabIndex={disableTabIndex ? -1 : undefined}
                >
                    <KeyboardNavigationItemDesktop
                        customEventKey={'ArrowDown'}
                        name={'decrease'}
                        onSelect={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                            decrement(event, count, steps, min, onDecrease)
                        }
                    />
                    <KeyboardNavigationItemDesktop
                        customEventKey={'ArrowLeft'}
                        name={'decrease'}
                        onSelect={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                            decrement(event, count, steps, min, onDecrease)
                        }
                    />
                    <MinusIcon
                        color={isMinusIconActive ? color('endeavour') : color('dustyGray')}
                        height={16}
                        width={16}
                    />
                </button>

                <div className={`flex-center ${styles.count}`}>{count}</div>

                <button
                    className={styles.button}
                    data-qa-id={'qa-plus-button'}
                    onClick={(event) => increment(event, count, steps, onIncrease, max)}
                    tabIndex={disableTabIndex ? -1 : undefined}
                >
                    <KeyboardNavigationItemDesktop
                        customEventKey={'ArrowUp'}
                        name={'increase'}
                        onSelect={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                            increment(event, count, steps, onIncrease, max)
                        }
                    />
                    <KeyboardNavigationItemDesktop
                        customEventKey={'ArrowRight'}
                        name={'increase'}
                        onSelect={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                            increment(event, count, steps, onIncrease, max)
                        }
                    />
                    <PlusIcon
                        color={isPlusIconActive ? color('endeavour') : color('dustyGray')}
                        height={16}
                        width={16}
                    />
                </button>
            </div>
        </KeyboardNavigationContainerDesktop>
    );
}
