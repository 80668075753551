import type { TravelFormDataV1Attributes } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type {
    ApplyTravelFormFilter,
    TravelFormDesktopFormConfiguration,
} from '@/features/travel-form/travel-form-desktop/travel-form-desktop';
import type { TravelFormOverlayName } from '@/features/travel-form/travel-form-expanded/travel-form-expanded';

import React from 'react';

import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import TravelFormInputWrapperDesktop from '@/features/travel-form/travel-form-desktop/travel-form-input-desktop/travel-form-input-desktop';
import TravelFormRoomAllocationDesktop from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-overlay-desktop/travel-form-room-allocation-desktop';
import { getRoomAllocationAsString } from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-service';
import useTravelFormRoomAllocation from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/use-travel-form-room-allocation';

type TravelFormRoomAllocationDesktopContainerProps = {
    formConfiguration: TravelFormDesktopFormConfiguration;
    hasError: boolean;
    inputName: TravelFormOverlayName;
    isLastOverlay?: boolean;
    isOverlayVisible: boolean;
    onInputClick: (inputName: string) => void;
    onOverlayClose: () => void;
    onOverlayCtaClick: (config: ApplyTravelFormFilter) => void;
    onTravelFormCtaClick?: () => void;
    roomAllocation: TravelFormDataV1Attributes['filters']['roomAllocation'] | undefined;
};

export default function TravelFormRoomAllocationContainerDesktop({
    formConfiguration,
    hasError,
    inputName,
    isLastOverlay = false,
    isOverlayVisible,
    onInputClick,
    onOverlayClose,
    onOverlayCtaClick,
    onTravelFormCtaClick,
    roomAllocation,
}: TravelFormRoomAllocationDesktopContainerProps) {
    const {
        activeRoomAllocationPlaceholder,
        addAdult,
        addChild,
        addRoom,
        allocateAge,
        allocation,
        isCustomAllocationLayerOpen,
        isValidAllocation,
        removeAdult,
        removeChild,
        removeRoom,
        setCustomAllocationLayerOpen,
        syncListOfChildren,
    } = useTravelFormRoomAllocation(
        roomAllocation?.configuration.preSelectedRooms || [],
        roomAllocation?.configuration.limits.maxAdultsPerRoom || 0,
        roomAllocation?.configuration.limits.maxChildrenPerRoom || 0,
    );

    const inputConfiguration = formConfiguration.fields?.roomAllocation;
    const isDataIncomplete = !roomAllocation;
    const inputLabel = roomAllocation?.inputLabel ?? inputConfiguration?.inputLabel;
    const placeholderText = roomAllocation?.placeholderText ?? inputConfiguration?.placeholderText;

    const handleSubmit = () => {
        if (!roomAllocation?.name) {
            return;
        }
        syncListOfChildren();
        setCustomAllocationLayerOpen(false);
        onOverlayCtaClick({
            filterName: roomAllocation.name,
            filterValue: getRoomAllocationAsString(allocation.rooms),
        });
    };

    const handleClose = () => {
        setCustomAllocationLayerOpen(false);
        return onOverlayClose();
    };

    return (
        <KeyboardNavigationContainerDesktop
            autoFocus={true}
            className={'flex-column width-100 relative row-gap-1'}
            containerId={'travel-form-room-allocation-overlay-container-desktop'}
            isActive={isOverlayVisible}
            onCtaClick={() => {
                handleSubmit();
                setTimeout(() => {
                    onTravelFormCtaClick?.();
                }, 200);
            }}
            onFocus={() => {
                if (!isOverlayVisible) {
                    onInputClick(inputName);
                }
            }}
        >
            <TravelFormInputWrapperDesktop
                ctaText={
                    (allocation.adultsCount + allocation.childrenCount > 1
                        ? roomAllocation?.ctaText.plural
                        : roomAllocation?.ctaText.singular) ?? 'Reisende Personen übernehmen'
                }
                filterValue={activeRoomAllocationPlaceholder ?? roomAllocation?.placeholderText ?? null}
                hasError={hasError}
                inputSize={formConfiguration.inputSize}
                isCtaDisabled={!isValidAllocation || allocation.errors.doChildrenWithoutAgeExistInCustomAllocation}
                isInputDisabled={true}
                isLastOverlay={isLastOverlay}
                isOverlayVisible={isOverlayVisible}
                label={inputLabel ?? '2 Erwachsene, 1 Zimmer'}
                onClick={() => onInputClick(inputName)}
                onClose={handleClose}
                onCtaClick={handleSubmit}
                placeholder={
                    isOverlayVisible && activeRoomAllocationPlaceholder
                        ? activeRoomAllocationPlaceholder
                        : (placeholderText ?? 'Reisende Personen')
                }
                qaId={'qa-travel-form-input-room-allocation-desktop'}
                type={'cta'}
            >
                <TravelFormRoomAllocationDesktop
                    addAdult={addAdult}
                    addChild={addChild}
                    addRoom={addRoom}
                    allocateAge={allocateAge}
                    allocation={allocation}
                    isCustomAllocationLayerOpen={isCustomAllocationLayerOpen}
                    isLoading={isDataIncomplete}
                    isValidAllocation={isValidAllocation}
                    removeAdult={removeAdult}
                    removeChild={removeChild}
                    removeRoom={removeRoom}
                    roomAllocation={roomAllocation ?? null}
                    setCustomAllocationLayerOpen={setCustomAllocationLayerOpen}
                />
            </TravelFormInputWrapperDesktop>
        </KeyboardNavigationContainerDesktop>
    );
}
