import type { TravelFormRooms } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import KeyboardNavigationItemDesktop from '@/core/components/dropdown/keyboard-navigation-item-desktop';
import { InfoIcon } from '@/core/features';
import color from '@/core/features/styles/color';
import {
    getAdultsInRoom,
    getChildrenInRoom,
} from '@/features/travel-form/travel-form-overlay/travel-form-room-allocation-overlay/travel-form-room-allocation-service';

import styles from './travel-form-room-allocation-preview.module.scss';

type TravelFormRoomWithManyRoomsProps = {
    rooms: TravelFormRooms;
    setRoomOverlayOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function TravelFormAdultRoomAllocationPreview({
    rooms,
    setRoomOverlayOpen,
}: TravelFormRoomWithManyRoomsProps) {
    const keyboardContainerStyle: React.CSSProperties = {
        borderRadius: '10px',
        display: 'inline-block',
        height: 'fit-content',
        outline: '10px solid #005ea8',
        outlineOffset: '4px',
        outlineWidth: '2px',
    };

    return (
        <div className={styles.mainRoomsWrapper}>
            <div className={styles.roomDescriptionWraper}>
                <div>Deine Zimmerbelegung</div>
                <div className={styles.roomHint}>
                    <InfoIcon
                        color={color('dustyGray')}
                        height={14}
                        width={14}
                    />
                    <div>Jedem Zimmer muss mind. ein Erwachsener zugeordnet sein.</div>
                </div>
            </div>
            <div className={styles.roomsWrapper}>
                <div className={styles.roomWrapper}>
                    {rooms.map((room, index) => {
                        const adults = getAdultsInRoom(room);
                        const children = getChildrenInRoom(room).map((age) => `${age}J.`);
                        const childrenAllocationText = children.length > 0 ? `(${children.join(', ')})` : '';

                        return (
                            <div
                                className={styles.roomInfoWrapper}
                                key={index}
                            >
                                <div className={styles.roomConfigHeaderTitle}>{`Zimmer ${index + 1}`}</div>
                                <div>{`${adults.length} ${adults.length === 1 ? 'Erwachsener' : 'Erwachsene'}`}</div>
                                <div>{`${children.length} ${
                                    children.length === 1 ? 'Kind' : 'Kinder'
                                } ${childrenAllocationText}`}</div>
                            </div>
                        );
                    })}
                </div>
                <KeyboardNavigationContainerDesktop
                    activeOnFocus={true}
                    containerId={'travel-form-room-allocation-preview-change-assignment'}
                    containerStyleWhileActive={keyboardContainerStyle}
                    hasCustomEvents={true}
                >
                    <KeyboardNavigationItemDesktop
                        customEventKey={' '}
                        name={'Belegung'}
                        onSelect={() => setRoomOverlayOpen(true)}
                    >
                        <div
                            className={styles.changeRoomText}
                            data-qa-id={'qa-travel-form-room-allocation-change'}
                            onClick={() => setRoomOverlayOpen(true)}
                        >
                            Belegung ändern
                        </div>
                    </KeyboardNavigationItemDesktop>
                </KeyboardNavigationContainerDesktop>
            </div>
        </div>
    );
}
