import type {
    TravelFormDurationPreset,
    TravelFormDurationSectionKey,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import CounterDesktop from '@/core/components/counter/desktop/counter-desktop';
import KeyboardNavigationContainerDesktop from '@/core/components/dropdown/keyboard-navigation-container-desktop';
import KeyboardNavigationItemDesktop from '@/core/components/dropdown/keyboard-navigation-item-desktop';
import {
    useActiveKeyboardItemName,
    useActiveKeyboardNavigationContainerIdAtom,
} from '@/core/components/dropdown/use-keyboard-navigation-container-desktop';
import RangeSliderDesktop from '@/core/components/range-slider/range-slider-desktop/range-slider-desktop';
import { Columns } from '@/core/features';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './travel-form-duration-desktop.module.scss';

const bem = bemModule(styles);

type TravelFormDurationDesktopProps = {
    activeModule: TravelFormDurationSectionKey;
    activePresetChipText: null | string;
    currentRangeValue: { max: number; min: number };
    dayCounter: number;
    dayRangeMax: number;
    dayRangeMin: number;
    exactDays: { text: string; value: string } | null;
    onActiveModuleChange: (activeModule: TravelFormDurationSectionKey) => void;
    onCalendarPickClick: (exactDays: string) => void;
    onDayCountDecrease: (nextCount: number) => void;
    onDayCountIncrease: (nextCount: number) => void;
    onPresetChipClick: (presetChip: TravelFormDurationPreset, enableNextInputOverlay?: boolean) => void;
    onRangeChange: (range: { max: number; min: number }) => void;
    onTravelFormCtaClick?: () => void;
    presetChips: TravelFormDurationPreset[];
    rangeStep: number;
};

export default function TravelFormDurationDesktop({
    activeModule,
    activePresetChipText,
    currentRangeValue,
    dayCounter,
    dayRangeMax,
    dayRangeMin,
    exactDays,
    onActiveModuleChange,
    onCalendarPickClick,
    onDayCountDecrease,
    onDayCountIncrease,
    onPresetChipClick,
    onRangeChange,
    onTravelFormCtaClick,
    presetChips,
    rangeStep,
}: TravelFormDurationDesktopProps) {
    const handlePresetChipClick = (presetChip: TravelFormDurationPreset, enableNextInputOverlay?: boolean) => {
        onActiveModuleChange('preset');
        return onPresetChipClick(presetChip, enableNextInputOverlay);
    };

    const handleDayCountDecrease = (nextValue: number) => {
        onActiveModuleChange('counter');
        return onDayCountDecrease(nextValue);
    };

    const handleDayCountIncrease = (nextValue: number) => {
        onActiveModuleChange('counter');
        return onDayCountIncrease(nextValue);
    };

    const handleOnRangeChange = (range: { max: number; min: number }) => {
        onActiveModuleChange('range');
        return onRangeChange(range);
    };

    const handleExactDaysClick = (exactDays: string) => {
        onActiveModuleChange('exactDays');
        return onCalendarPickClick(exactDays);
    };

    const activeKeyboardItemName = useActiveKeyboardItemName();
    const activeKeyboardNavigationContainerId = useActiveKeyboardNavigationContainerIdAtom();

    return (
        <div className={styles.overlayContent}>
            {presetChips.length > 0 && (
                <ContentContainer>
                    <KeyboardNavigationContainerDesktop
                        activeOnFocus={true}
                        containerId={'travel-form-duration-desktop-preset-chips'}
                        gridColumn={3}
                        gridType={'Grid'}
                        onCtaClick={() => {
                            const highlightedPreset = presetChips.find(
                                (preset) => preset.text === activeKeyboardItemName,
                            );
                            if (highlightedPreset) {
                                handlePresetChipClick(highlightedPreset);
                            }
                            setTimeout(() => {
                                onTravelFormCtaClick?.();
                            }, 200);
                        }}
                        onFocus={() => {
                            onActiveModuleChange('preset');
                        }}
                    >
                        <Columns
                            count={3}
                            disableLastItemStretch={true}
                            gap={14}
                        >
                            {presetChips.map((presetChip, index) => {
                                return (
                                    <KeyboardNavigationItemDesktop
                                        key={`${presetChip.value}-${index}`}
                                        name={presetChip.text}
                                        onSelect={() => handlePresetChipClick(presetChip, true)}
                                    >
                                        <div
                                            className={bem(styles.presetChip, {
                                                isActive:
                                                    presetChip.value === activePresetChipText &&
                                                    activeModule === 'preset',
                                            })}
                                            data-qa-id={'qa-travel-form-duration-preset-chip-desktop'}
                                            onClick={() => handlePresetChipClick(presetChip)}
                                            style={{
                                                outline:
                                                    activeKeyboardItemName === presetChip.text &&
                                                    activeKeyboardNavigationContainerId ===
                                                        'travel-form-duration-desktop-preset-chips'
                                                        ? '1px solid #005ea8'
                                                        : undefined,
                                                outlineOffset:
                                                    presetChip.text === activeKeyboardItemName ? '2px' : undefined,
                                            }}
                                        >
                                            {presetChip.text}
                                        </div>
                                    </KeyboardNavigationItemDesktop>
                                );
                            })}
                        </Columns>
                    </KeyboardNavigationContainerDesktop>
                </ContentContainer>
            )}
            <ContentContainer>
                <BorderedContainer
                    isActive={activeModule === 'counter'}
                    onClick={() => onActiveModuleChange('counter')}
                >
                    <div className={styles.dayCounterWrapper}>
                        <span>Genau</span>
                        <CounterDesktop
                            count={dayCounter}
                            disableTabIndex={true}
                            keyboardNavigationContainerId={'travel-form-duration-desktop-day-counter'}
                            max={dayRangeMax}
                            min={dayRangeMin}
                            onDecrease={handleDayCountDecrease}
                            onIncrease={handleDayCountIncrease}
                            onKeyboardActive={() => onActiveModuleChange('counter')}
                        />
                        <span>Tage</span>
                    </div>
                </BorderedContainer>
            </ContentContainer>
            <ContentContainer>
                <BorderedContainer
                    isActive={activeModule === 'range'}
                    onClick={() => onActiveModuleChange('range')}
                >
                    <div className={styles.rangeSliderContainer}>
                        <div className={styles.rangeSliderWrapper}>
                            <RangeSliderDesktop
                                disableTabIndex={true}
                                keyboardNavigationContainerId={'travel-form-duration-desktop-range'}
                                max={dayRangeMax}
                                min={dayRangeMin}
                                onChange={handleOnRangeChange}
                                onKeyboardActive={() => onActiveModuleChange('range')}
                                step={rangeStep}
                                value={currentRangeValue}
                            />
                        </div>
                        <div className={'flex-space-between-horizontal'}>
                            <span className={styles.rangeNumber}>{`${currentRangeValue.min} Tage`}</span>
                            <span>bis</span>
                            <span className={styles.rangeNumber}>{`${currentRangeValue.max} Tage`}</span>
                        </div>
                    </div>
                </BorderedContainer>
            </ContentContainer>
            {exactDays && (
                <ContentContainer>
                    <KeyboardNavigationContainerDesktop
                        activeOnFocus={true}
                        containerId={'travel-form-duration-desktop-excat-days'}
                        hasCustomEvents={true}
                        onFocus={() => onActiveModuleChange('exactDays')}
                    >
                        <KeyboardNavigationItemDesktop
                            customEventKey={' '}
                            name={'exactDays'}
                            onSelect={() => handleExactDaysClick(exactDays.value)}
                        >
                            <BorderedContainer
                                isActive={activeModule === 'exactDays'}
                                onClick={() => onActiveModuleChange('exactDays')}
                            >
                                <div
                                    className={`flex-center ${styles.exactDays}`}
                                    onClick={() => handleExactDaysClick(exactDays.value)}
                                >
                                    <span>Wie im Kalender angegeben</span>
                                    <span className={styles.exactDaysLabel}>{exactDays.text}</span>
                                </div>
                            </BorderedContainer>
                        </KeyboardNavigationItemDesktop>
                    </KeyboardNavigationContainerDesktop>
                </ContentContainer>
            )}
        </div>
    );
}

const ContentContainer = ({ children }: React.PropsWithChildren) => {
    return <div className={styles.contentContainer}>{children}</div>;
};
const BorderedContainer = ({
    children,
    isActive,
    onClick,
}: React.PropsWithChildren<{ isActive: boolean; onClick?: () => void }>) => {
    return (
        <div
            className={`flex-center ${bem(styles.borderedContainer, { isActive })}`}
            onClick={onClick}
        >
            {children}
        </div>
    );
};
